import {
    computed,
    onMounted,
    reactive,
    ref,
    watch,
} from "vue"
import { useI18n } from "vue-i18n"
import {
    useRoute,
    useRouter,
} from "vue-router"
import { useStore } from "vuex"

import { useCopilot } from "@/components/copilot/use-copilot"
import {
    ISideNavAction,
    ISideNavSection,
    ISideNavTab,
} from "@/components/side-nav"
import { FeatureId } from "@/composables/use-licence-controller"
import { useSelectedTitle } from "@/composables/use-selected-title"
import { DOCUMENT_SOURCE } from "@/consts/document-source"
import { Route } from "@/enums/route.enum"
import {
    getStepName,
    SearchesSteps,
} from "@/enums/searches.enum"
import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
import { SET_UPLOAD_DOCUMENTS_DIALOG_STATE } from "@/store/modules/documents/documents-types"
import { SEARCHES_MUTATE_MAP_KEY } from "@/store/modules/searches/types"

export interface ITitlePanelTabController {
    setOptions: (options: { scrollToSection: boolean }) => void
    getOptions: () => { scrollToSection: boolean }
    getTabs: (intialise?: boolean) => void
    getActiveTab: () => ISideNavTab
    getActiveSection: () => ISideNavSection
    addTabSections: (tabKey: string, sections: ISideNavSection[]) => void
    addTabSection: (tabKey: string, section: ISideNavSection) => void
    clearSection: (tabKey: string) => void
}

export interface ITitlePanelActionController {
    getDefaultActions: () => void
    clearActions: () => void
    addActions: (actions: ISideNavAction[]) => void
    addAction: (action: ISideNavAction) => void
}

const useTitlePanel = (args?: {
    isFullScreen?: boolean
}) => {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const {selectedTitleNumber, titleRecordSource, isRegisterPurchased, boundaryAvailable} = useSelectedTitle()
    const { setTitleNumbers, setMatterId, copilotUrl } = useCopilot()
    const isFindNearbyEnabled = computed(() => store.state.config?.featureFlags?.findNearby)
    const currentMatterId = computed((): number => store.state.matter.currentMatter.id)

    const { t } = useI18n()
    const tabs = reactive<ISideNavTab[]>([])
    const actions = ref<ISideNavAction[]>([])
    const activeTabId = ref<string>(TitlePanelTabName.Summary)
    const activeSectionId = ref<string>(null)
    const tabController = ref<ITitlePanelTabController>(null)
    const actionController = ref<ITitlePanelActionController>(null)
    const scrollToSection = ref<boolean>(true)
    const tabSections = ref<Map<string, ISideNavSection[]>>(new Map())

    const showRegisterTab = computed(() => {
        return !args?.isFullScreen || isRegisterPurchased.value
    })

    const showPlanningTab = computed(() => {
        return boundaryAvailable.value && (!args?.isFullScreen || isRegisterPurchased.value)
    })

    const showFindNearbyTab = computed(() => {
        return isFindNearbyEnabled.value && boundaryAvailable.value
    })

    /**
     * Get the tabs to render based on the title record source
     */
    const getTabs = () => {
        let tabsToRender: ISideNavTab[] = [{
            key: TitlePanelTabName.Summary,
            id: "titlePanelTabSummary",
            dataTestId: "title-panel-tab-summary",
            dataTrackId: "TITLE-DETAILS-PANEL - Summary tab",
            title: t("titlePanel.tabs.summary"),
        },
        {
            key: TitlePanelTabName.Register,
            id: "titlePanelTabRegister",
            dataTestId: "title-panel-tab-register",
            dataTrackId: "TITLE-DETAILS-PANEL - Register tab",
            title: t("titlePanel.tabs.register"),
        },
        {
            key: TitlePanelTabName.CopiesFiled,
            id: "titlePanelTabOfficialCopies",
            dataTestId: "title-panel-tab-copies-filed",
            dataTrackId: "TITLE-DETAILS-PANEL - Copies filed tab",
            title: t("titlePanel.tabs.officialCopies"),
        },
        {
            key: TitlePanelTabName.Leases,
            id: "titlePanelTabLeases",
            dataTestId: "title-panel-tab-leases",
            dataTrackId: "TITLE-DETAILS-PANEL - Lease hierarchy tab",
            title: t("titlePanel.tabs.leaseHierarchy"),
        },
        {
            key: TitlePanelTabName.DayList,
            id: "titlePanelTabDayList",
            dataTestId: "title-panel-tab-daylist",
            dataTrackId: "TITLE-DETAILS-PANEL - Daylist tab",
            title: t("titlePanel.tabs.dayList"),
        },
        {
            key: TitlePanelTabName.Planning,
            id: "titlePanelTabPlanning",
            dataTestId: "title-panel-tab-planning",
            dataTrackId: "TITLE-DETAILS-PANEL - Planning tab",
            title: t("titlePanel.tabs.planning"),
            featureId: FeatureId.Planning,
        },
        {
            key: TitlePanelTabName.FindNearby,
            id: "titlePanelTabNearby",
            dataTestId: "title-panel-tab-find-nearby",
            dataTrackId: "TITLE-DETAILS-PANEL - Find Nearby tab",
            title: t("titlePanel.tabs.nearby"),
            featureId: FeatureId.Nearby,
        },
        {
            key: TitlePanelTabName.Epc,
            id: "titlePanelTabEpc",
            dataTestId: "title-panel-tab-epc",
            dataTrackId: "TITLE-DETAILS-PANEL - Epc",
            title: t("titlePanel.tabs.epc"),
        }]
        // Scotland only has summary and register tabs
        switch (titleRecordSource.value) {
            case DOCUMENT_SOURCE.SCOTLAND:
                // Scotland only has summary and register tabs
                tabsToRender = tabsToRender.filter(category => [TitlePanelTabName.Summary, TitlePanelTabName.Register, TitlePanelTabName.DayList].includes(category.key as TitlePanelTabName))
                break
        }

        // remove categories that are not enabled
        tabsToRender = tabsToRender.filter((category) => {
            if (category.key === TitlePanelTabName.Register && !showRegisterTab.value) return false
            if (category.key === TitlePanelTabName.Planning && !showPlanningTab.value) return false
            return !(category.key === TitlePanelTabName.FindNearby && !showFindNearbyTab.value)

        })

        if (tabsToRender.length === 0) {
            return
        }

        // add the tab sections
        if (!tabSections.value?.size) {
            tabsToRender.forEach(tab => {
                tabSections.value.set(tab.key, [])
            })
        } else {
            tabsToRender.forEach(tab => {
                tab.sections = tabSections.value.get(tab.key) ?? []
            })
        }
        // set the tabs
        tabs.splice(0, tabs.length, ...tabsToRender)

        // set the active tab
        setActiveTabSection()

    }

    const setActiveTabSection = () => {
        // set the active section
        if (route.query?.section) {
            // find section in the active tab
            const section = tabs.find(tab => tab.key === activeTabId.value)?.sections?.find(section => section.id === route.query.section)
            if (section) {
                activeSectionId.value = section.id
            }
        } else {
            // set the first section as active
            activeSectionId.value = tabs.find(tab => tab.key === activeTabId.value)?.sections?.[0]?.id
        }

        // set tab expanded
        const tab = tabs.find(tab => tab.key === activeTabId.value)
        if (tab) {
            tab.isExpanded = true
        }
    }

    /**
     * Add sections to a tab
     * @param tabKey - the tab key to add the sections to
     * @param sections - the sections to add
     */
    const addTabSections = (tabKey: string, sections: ISideNavSection[]) => {
        for (const section of sections) {
            addTabSection(tabKey, section)
        }
    }

    /**
     * Add a section to a tab
     * @param tabKey - the tab key to add the section to
     * @param section - the section to add
     */
    const addTabSection = (tabKey: string, section: ISideNavSection) => {
        let sections = tabSections.value.get(tabKey)
        if (!sections) {
            sections = []
        }

        // check if the section already exists
        if (!sections.find(s => s.id === section.id)) {
            sections.push({
                ...section,
                title: section?.title ?? t(`titlePanel.tabs.sections.${ tabKey.toLowerCase() }.${ section.id }`),
                id: section.id,
            })
        }

        // sort sections by order
        sections = sections.sort((a: ISideNavSection, b: ISideNavSection) => {
            return a.order - b.order
        })

        tabSections.value.set(tabKey, sections)
        const tab = tabs.find(tab => tab.key === tabKey)
        if (tab) {
            tab.sections = sections
        }
    }

    /**
     * Get the default actions for the title panel
     */
    const getDefaultActions = () => {
        actions.value = [
            {
                title: "Upload Documents",
                id: "uploadDocuments",
                onClick: () => {
                    store.commit(SET_UPLOAD_DOCUMENTS_DIALOG_STATE, true)
                },
            },
            {
                title: "Order Searches",
                id: "orderSearches",
                onClick: async () => {
                    await router.push({
                        name: Route.MatterSearchesCreate,
                        params: {
                            args: selectedTitleNumber.value,
                            matterId: currentMatterId.value.toString(),
                            step: getStepName(SearchesSteps.DefinePolygon),
                        },
                    })
                    store.commit(SEARCHES_MUTATE_MAP_KEY)
                },
            },
            {
                title: "Send to Copilot",
                id: "sendToCopilot",
                icon: 'ow-icon-copilot',
                onClick: () => {
                    setTitleNumbers([selectedTitleNumber.value])
                    setMatterId(currentMatterId.value)
                    window.open(copilotUrl.value, '_blank')
                },
            },
        ]
    }

    /**
     * Clear the actions
     */
    const clearActions = () => {
        actions.value = []
    }

    /**
     * Add actions to the title panel
     * @param sideNavActions - the actions to add
     */
    const addActions = (sideNavActions: ISideNavAction[]) => {
        // don't add duplicate actions
        if (!actions.value) {
            actions.value = []
        }
        sideNavActions.forEach(action => {
            if (!actions.value.find(a => a.id === action.id)) {
                actions.value.push(action)
            }
        })
    }

    /**
     * Add an action to the title panel
     * @param sideNavAction - the action to add
     */
    const addAction = (sideNavAction: ISideNavAction) => {
        if (!actions.value) {
            actions.value = []
        }

        // check if the action already exists
        if (!actions.value.find(action => action.id === sideNavAction.id)) {
            actions.value.push(sideNavAction)
        }
    }

    /**
     * Clear the sections for a tab
     * @param categoryKey - the tab key to clear the sections for
     */
    const clearSection = (categoryKey: string) => {
        const category = tabs.find(category => category.key === categoryKey)
        if (category) {
            category.sections = []
        }
    }

    // on mounted, set the tab and action controllers
    onMounted(() => {
        tabController.value = {
            getTabs,
            addTabSections,
            addTabSection,
            clearSection,
            setOptions: (options) => {
                scrollToSection.value = options?.scrollToSection ?? true
            },
            getOptions: () => {
                return {
                    scrollToSection: scrollToSection.value,
                }
            },
            getActiveTab: () => {
                return tabs.find(tab => tab.key === activeTabId.value)
            },
            getActiveSection: () => {
                return tabs.find(tab => tab.key === activeTabId.value)?.sections?.find(section => section.id === activeSectionId.value)
            },
        }

        actionController.value = {
            getDefaultActions,
            clearActions,
            addActions,
            addAction,
        }

    })

    /**
     * Set the selected tab and section
     * @param args
     * @param args.tabId - the tab id to set
     * @param args.sectionId - the section id to set
     * @param args.replace - whether to replace the current route
     */
    const setSelectedTab = async (args: { tabId: string; sectionId: string; replace?: boolean } = {
        tabId: null,
        sectionId: null,
    }) => {
        const {tabId, sectionId} = args
        if (!route.params?.titleNumber || route.params?.titleNumber === selectedTitleNumber.value) {
            // set the query params
            const query: Record<string, string> = {}
            if (tabId) {
                query.tab = tabId
            }
            if (sectionId) {
                query.section = sectionId
            }

            // if the tab is the same as the current tab, we need to replace the route
            // we don't want to add a new entry to the history when a section is changed
            const routeFunc = args.replace ? router.replace : router.push
            await routeFunc({
                query,
            })
        }
    }

    // watch the query params and set the active tab and section
    watch(() => route, async (val) => {
        // if the route is not the title panel route, return
        if (route?.name !== Route.MatterMapTitle) {
            return
        }

        // get the query params
        const query = val.query
        let tabValue = query?.tab ?? TitlePanelTabName.Summary
        const sectionValue = query?.section

        // if hash for backwards compatibility
        if (route.hash) {
            tabValue = route.hash.replace('#', '').split('/')?.[0]
        }

        // set the active tab
        activeTabId.value = tabValue as string

        // set the active section
        const firstSection = tabs.find(tab => tab.key === tabValue)?.sections?.[0]
        if (sectionValue) {
            activeSectionId.value = sectionValue as string ?? firstSection?.id
        } else {
            activeSectionId.value = firstSection?.id
        }
    }, {
        immediate: true,
        deep: true,
    })

    const isTitlePanelVisible = computed(() => route?.name === Route.MatterMapTitle && store.state?.title?.selectedTitleNumber)

    return {
        tabs,
        actions,
        tabController,
        actionController,

        activeTabId,
        activeSectionId,

        setSelectedTab,

        // testing
        route,
        router,

        isTitlePanelVisible,
    }
}

export default useTitlePanel
