<template>
    <section :class="{
                 'is-visible': showTitlePanel,
                 'matter-titles-collapsed': isMatterTitlesCollapsed,
             }"
             class="title-panel-v2">
        <title-panel-header :current-matter-id="matterId"
                            :is-loading="isLoading"
                            :is-ordering-allowed="isOrderingAllowed"
                            :ownership-mismatch="trOwnershipMismatch"
                            :has-drop-shadow="titlePanelDropShadow"
                            @pin="addToMatterHandler"
                            @close-panel="closePanel"
                            @open-register="openRegisterClickHandler" />
        <div :class="{
            'title-panel-v2__content': !tabIsRegister,
        }">
            <title-panel-content :is-full-screen="false"
                                 :is-loading="isLoading"
                                 :is-ordering-allowed="isOrderingAllowed"
                                 :matter-id="matterId"
                                 scroll-element-id="sideNavContent"
                                 @click="onTabClick"
                                 @scroll-content="onScroll"
                                 @title-number-selected="titleNumber => $emit('title-number-selected', titleNumber)"
                                 @set-selected-tab="setSelectedTab"
                                 @open-register="openRegisterClickHandler" />
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import TitleInformationApi from '@/api/title-information.api'
    import TitlePanelContent from "@/components/title-panel/v2/title-panel-content.vue"
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import useTitlePanel from "@/composables/use-title-panel"
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { Route } from '@/enums/route.enum'
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import { checkFlag } from '@/feature-flags'
    import { ITitleRegisterDocument } from '@/interfaces/official-copy-availability.interface'
    import { ADD_DOCUMENT_TO_MATTER } from '@/store/modules/documents/documents-types'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_LOG_FEATURE_USAGE,
    } from "@/store/mutation-types"
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import TitlePanelHeader from './title-panel-header.vue'

    const store = useStore()
    const router = useRouter()
    const {
        selectedTitle,
        selectedTitleNumber,
        titleRecordSource,
    } = useSelectedTitle()

    const props = defineProps({
        isOrderingAllowed: {
            type: Boolean,
            required: false,
            default: true,
        },
        matterId: Number,
    })

    const titlePanelDropShadow = ref<boolean>(false)

    const {setSelectedTab} = useTitlePanel()

    const emit = defineEmits<{
        (e: 'clear-titles', selectedTitleNumber: string): void
        (e: 'pin-title', selectedTitleNumber: string): void
        (e: 'title-number-selected', titleNumber: string): void
    }>()

    const isSharedLinkView = computed(() => store.getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`])
    const isWalkthrough = computed<boolean>((): boolean => store.state.walkthrough.enabled)
    const isOwnerTitlesPanelOpen = computed<boolean>((): boolean => store.state.search.isOwnerTitlesPanelOpen)
    const isMatterTitlesCollapsed = computed<boolean>(() => !store.state.user.showMapTitlesNav)
    const titleRegister = computed<ITitleRegisterDocument>(() => store.state.title?.selectedTitle?.officialCopiesAvailability?.results?.titleRegister)
    const planningData = computed(() => store.state.config?.featureFlags?.planningData)
    const tabIsRegister = computed<boolean>(() => router.currentRoute.value.query?.tab === 'register')


    const showTitlePanel = computed<boolean>((): boolean => {
        return !isSharedLinkView.value &&
            !isWalkthrough.value &&
            !isOwnerTitlesPanelOpen.value &&
            Boolean(selectedTitleNumber.value)
    })

    const closePanel = () => {
        emit('clear-titles', selectedTitleNumber.value)
    }

    const trOwnershipMismatch = ref<boolean>(false)
    const isLoading = computed<boolean>(() => !titleRecordSource.value)

    watch(() => selectedTitleNumber.value, async (newTitleNumber: string, oldTitleNumber: string) => {
        if (!isNullOrWhitespace(newTitleNumber)
            && newTitleNumber !== oldTitleNumber) {
            const trOwnershipFlag = await TitleInformationApi.checkOwnerMatchWithCcodOcodByTitleNumber(newTitleNumber)
            trOwnershipMismatch.value = trOwnershipFlag === false
        }
    }, { immediate: true })

    const openRegisterClickHandler = async (): Promise<void> => {
        if (checkFlag('review-assistant', true)) {
            await router.push({
                name: Route.ReviewAssistant,
                params: {
                    matterId: props.matterId.toString(),
                    titleNumber: selectedTitle.value?.titleNumber,
                },
            })
        } else {
            const documentId = titleRegister.value?.documentId
            if (documentId) {
                await store.dispatch(MATTER_ADD_TITLE, {
                    titleNumber: selectedTitle.value?.titleNumber,
                    show: true,
                })
                await store.dispatch(ADD_DOCUMENT_TO_MATTER, {
                    documentType: HighLevelDocumentType.Register,
                    documentId,
                    matterId: props.matterId.toString(),
                })

                await router.push({
                    name: Route.DocumentViewer,
                    params: {
                        documentType: HighLevelDocumentType.Register,
                        documentId,
                    },
                    query: {
                        fromMatterId: props.matterId.toString(),
                    },
                })
            } else {
                console.error('Unable to view document as no documentId in the store', titleRegister.value)
            }
        }
    }

    const addToMatterHandler = () => {
        emit('pin-title', selectedTitleNumber.value)
    }

    const onScroll = (scrollTop: number) => {
        titlePanelDropShadow.value = scrollTop > 0
    }

    const logLeases = async () => {
        await store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: 'Leases hierarchy',
            description: `opened lease tab for title ${ selectedTitleNumber.value }`,
        })
    }

    const trackHeapFindNearby = async () => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'TITLE-DETAILS-PANEL - Nearby tab',
        })
    }

    const trackHeapPlanning = async () => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Title panel - Planning tab',
        })

        await store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: planningData.value ? 'PLANNING_TAB_VIEWED' : 'PLANNING_PROMO_VIEWED',
            description: planningData.value ? 'Planning tab viewed' : 'Planning promo tab viewed',
        })
    }

    const onTabClick = async ({ tabId }: { tabId: string }) => {

        switch (tabId) {
            case TitlePanelTabName.Leases:
                await logLeases()
                break
            case  TitlePanelTabName.Planning:
                await trackHeapPlanning()
                break
            case TitlePanelTabName.FindNearby:
                await trackHeapFindNearby()
                break
        }
    }
</script>

<style lang="scss" scoped>
@import './title-panel';
</style>
