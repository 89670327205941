export default {
    matterIsClosed: 'This matter is closed',
    noMatters: 'There are no matters that match your filter',
    officialCopiesEmptyStateText: 'There are no copy documents filed against this register',
    officialCopiesAugmentingFailedText: 'Document enhancing not available due to file size.',
    reopenMatterText: 'Please reopen the matter from the matters dashboard to enable this feature',
    newAppVersion: 'New Version Available',
    newAppVersionMessage: 'A newer version of the application is available. With the old version, some functionalities might not work properly. Would you like to reload?',
    reloadApp: 'Reload now',
    backdatedInfoFlag: 'You are viewing backdated information from HM Land Registry, accurate as of {date}.',
    fakeDoorUploadDocumentsTitle: 'Thank you for your interest!',
    fakeDoorUploadDocumentsContent: 'Allowing you to upload {documentType} is something we’re exploring as a potential improvement to the platform. A member of our team will follow up with you with more details.',
    loremIpsumText: 'Cupcake ipsum dolor sit amet. Bear claw ice cream gummies topping brownie cupcake chocolate' +
        ' cake jelly-o topping.',
    buttons: {
        seeMore: 'See more',
        showLess: 'Show less',
        showMore: 'Show more',
        openTitle: 'Open Title',
        showOnMap: 'Show on map',
        readMore: 'Read More',
        copilot: 'Orbital Copilot',
        support: 'Contact Support',
        witness: 'Orbital Witness',
    },
    name: {
        orbitalWitness: 'Orbital Witness',
        hmlr: 'HMLR',
    },
    applicationRoles: {
        owUser: 'Witness',
        copilot: 'Copilot',
        residential: 'Residential',
        sysAdmin: 'System Administrator',
        orgAdmin: 'Organisation Administrator',
    },
    products: {
        witness: 'Orbital Witness',
        copilot: 'Orbital Copilot',
        residential: 'Orbital Residential',
    },
    error: {
        title: 'Error',
        hmlrOrderDocument: 'There was an error with HMLR. You haven\'t been charged and are able to retry the order.',
        noResults: 'No results',
        noResultsFound: 'No results found',
        noResultsFoundForSearchText: 'No results were found for {0}',
        errorSettingMatter: '<strong>Something went wrong initialising the matter.</strong><br /><br />Try refreshing the page.</br><br />If the problem persists, please contact support through the Intercom button on the bottom left of the screen.',
    },
    documentOrdering: {
        genericOrderingError: 'An error was encountered when ordering the document(s)',
    },
    action: {
        add: 'Add',
        addAll: 'Add all',
        addToMatter: 'Add to matter',
        addProducts: 'Add products',
        swapProduct: 'Swap product',
        addTitles: 'Add Titles',
        back: 'Back',
        cancel: 'Cancel',
        clear: 'Clear',
        clearAll: 'Clear all',
        clearFilter: 'Clear filter',
        close: 'Close',
        confirm: 'Confirm',
        ok: 'OK',
        copy: 'Copy',
        create: 'Create',
        createWalkthrough: 'Create Walkthrough',
        delete: 'Delete',
        done: 'Done',
        download: 'Download',
        downloadAll: 'Download all',
        downloadAsPdf: 'Download as PDF',
        downloadAsXlsx: 'Download as XLSX',
        downloadSnapshot: 'Download Snapshot',
        downloadInvoice: 'Download Invoice',
        dismiss: 'Dismiss',
        edit: 'Edit',
        editMatter: 'Edit Matter',
        editWalkthrough: 'Edit Walkthrough',
        enableMonitoring: 'Enable monitoring',
        export: 'Export',
        exportCsv: 'Export CSV',
        exportToExcel: 'Export to Excel',
        exportToExcelWithCount: 'Export to Excel ({count})',
        filterDocs: 'Filter documents...',
        giveFeedback: 'Give Feedback',
        imInterested: 'I\'m interested',
        learnMoreAboutMapLayer: 'Learn more about the layer',
        manage: 'Manage',
        markAllRead: 'Mark all as read',
        markAsRead: 'Mark as read',
        open: 'Open',
        order: 'Order',
        orderBackdated: 'Order (Backdated)',
        orderWithCost: 'Order ({cost})',
        orderBackdatedWithCost: 'Order (Backdated) ({cost})',
        orderRegister: 'Order Register',
        orderRegisterWithCost: 'Order Register ({cost})',
        orderRegisterBackdated: 'Order Register (Backdated)',
        orderRegisterBackdatedWithCost: 'Order Register (Backdated) ({cost})',
        orderPlan: 'Order Plan',
        orderPlanWithCost: 'Order Plan ({cost})',
        orderBackdatedPlan: 'Order Plan (Backdated)',
        orderBackdatedPlanWithCost: 'Order Plan (Backdated) ({cost})',
        overlay: 'Overlay',
        purchase: 'Purchase',
        redo: 'Redo',
        refresh: 'Refresh',
        refreshWithCost: 'Refresh ({cost})',
        refreshAllWithCost: 'Refresh all ({cost})',
        retryWithCost: 'Retry ({cost})',
        remove: 'Remove',
        resetZoom: 'Reset zoom',
        retry: 'Retry',
        save: 'Save',
        import: 'Import',
        saveChanges: 'Save Changes',
        savingChanges: 'Saving changes',
        scrollToEntry: 'Scroll to this entry',
        searchForMatters: 'Search matters',
        send: 'Send',
        select: 'Select',
        sendToIManage: 'Send to iManage',
        sendToOrbitalCopilot: 'Send to Orbital Copilot',
        share: 'Share',
        tryAgain: 'Try again',
        tryAnotherTerm: 'Please try another term',
        update: 'Update',
        undo: 'Undo',
        upload: 'Upload',
        uploadDocument: 'Upload document',
        view: 'View',
        viewBackdated: 'View (Backdated)',
        viewFile: 'View file',
        viewTitle: 'View title',
        zoomIn: 'Zoom in',
        zoomOut: 'Zoom out',
    },
    label: {
        added: 'Added',
        address: 'Address',
        all: 'All',
        alerts: 'Alerts',
        availability: 'Availability',
        backdated: 'Backdated',
        changesSaved: 'Changes saved',
        chargeFrom: 'Charge from',
        comingSoon: 'Coming soon',
        dataSource: 'Data source',
        document: 'Document',
        documents: 'Documents',
        enabled: 'Enabled',
        entries: 'Entries',
        exporting: 'Exporting',
        filedUnder: 'Filed under',
        free: 'Free',
        freehold: 'Freehold',
        hideDetails: 'Hide Details',
        invalidEmail: 'Invalid email address',
        leasehold: 'Leasehold',
        leaseTerm: 'Lease term',
        matters: 'Matters',
        monitors: 'Monitors',
        more: 'more',
        no: 'No',
        noCorporateOwnersFound: 'No corporate owners found',
        noPlacesFound: 'No places found',
        notApplicable: 'N/A',
        noTitlesFound: 'No titles found',
        owner: 'Owner',
        reference: 'Reference',
        registerInterest: 'Register interest',
        removed: 'Removed',
        required: 'Required',
        scottishSearchWarning: "If your search originates in Scotland, you must use the {link} button on the left hand side panel to add titles",
        scottishSearchWarningHomePage: "Searching for Scottish titles is not yet supported, please first create a matter to add and view the title",
        showDetails: 'Show Details',
        showing: 'Showing',
        showingResults: 'Showing {count} results',
        source: 'Source',
        status: 'Status',
        summary: 'Summary',
        tenure: 'Tenure',
        titleNumber: 'Title no.',
        total: 'Total',
        type: 'Type',
        unknown: 'Unknown',
        updated: 'Updated',
        updatedOn: 'Updated on',
        weakPassword: 'Password is not strong enough',
        yes: 'Yes',
    },
    message: {
        copied: 'Copied',
        nextSteps: 'Next steps',
        errorOccurred: 'An error has occurred',
        searchOutOfMatter: 'Looking for something not in your matter?',
        dataQuery: 'What other data sets would you like to see added?',
    },
    homepage: {
        header: 'Homepage',
        welcomeTitle: 'Welcome, <span class="hide-in-percy">{name}</span>',
        welcomeBackTitle: 'Welcome back, <span class="hide-in-percy">{name}</span>',
        subtitle: 'What would you like to do today?',
        recentMatters: 'Recent matters',
        cards: {
            titleLookup: {
                title: 'Title Lookup',
                subtitle: 'Quickly identify key info on a title',
            },
            getStarted: {
                title: 'Get Started',
                subtitle: 'Visualise titles and collaborate on projects',
            },
            generateReports: {
                title: 'Generate Reports',
                subtitle: 'Easily create reports on titles',
            },
            copilotCtaCopilotUser: {
                title: 'Orbital Copilot',
                subtitle: 'Analyse documents and generate reports fast with Orbital Copilot.',
            },
            copilotCtaWitnessUser: {
                title: 'Orbital Copilot',
                subtitle: 'Cut up to 70% of your time spent on due diligence.',
                infoMessage: 'Talk to our team about getting set up or any queries.',
                defaultVideoTitle: 'Orbital Copilot intro video.',
            },
        },
        actions: {
            createNewMatter: 'Create new matter',
            generateReport: 'Generate report',
            viewAll: 'View all',
            goToCopilot: 'Go to Orbital Copilot',
            learnMore: 'Learn More',
        },
    },
    mattersList: {
        header: 'Matters List',
    },
    checkout: {
        message: {
            orderComplete: 'Order complete',
            orderDocument: 'Order document',
            orderReadyToDownload: 'Your order now is ready to download',
            processingOrder: 'Processing order',
            processingDocuments: 'Processing documents',
        },
        label: {
            reviewOrder: 'Review order',
            quickOrder: 'Quick order',
            confirmOrder: 'Confirm order',
        },
    },
    orderTitlePlan: {
        viewOverlay: 'View Overlay',
        overlayPlan: 'Overlay Plan',
    },
    documents: {
        officialCopies: 'Official copies',
        pendingApplications: 'Pending applications',
        titleRegister: 'Title Register',
        titlePlan: 'Title Plan',
        titleSheet: 'Title Sheet',
        titleSheetTitlePlan: 'Title Sheet & Title Plan',
        searchFrom: 'Search from: {date}',
        issuedOn: 'Issued: {date}',
        edition: 'Edition: {date}',
        registerPurchased: 'Register purchased.',
        registerUploaded: 'Register uploaded.',
        registerOwnerMismatchesPublicData: 'Register out of date, new owner detected in latest public data.',
        oldRegisterPurchased: 'Register purchased. Issued on date is over 3 months ago.',
        oldRegisterUploaded: 'Register uploaded. Issued on date is over 3 months ago.',
        oldRegister: ' Issued on date is over 3 months ago.',
        oldTitlePlan: 'Issued on date is over 3 months ago.',
        noRegisterEntries: 'No register entries found.',
        noRegisterEntriesPurchase: 'Purchase the register to view.',
        error: {
            orderDocuments: 'Failed to order documents: {message}',
            registerUnavailable: 'Register unavailable',
            failedOrder: 'There was an error with ordering this document. You have not been charged',
        },
        library: {
            tabs: {
                purchased: 'Purchased ({count})',
                officialCopies: 'Official Copies ({count})',
                uploaded: 'Uploaded ({count})',
                searches: 'Searches ({count})',
                exported: 'Exported ({count})',
            },
            documentsCount: ' {count} documents',
            successfullyUploadedDocumentsCount: '{count} succesfully uploaded',
            failedToUploadDocumentsCount: '{count} failed to upload',
            productsCount: ' over {count} products',
            searchesColumns: {
                product: 'Product',
                location: 'Location',
                titleOrAddress: 'Title/Address',
                receivedOn: 'Received On',
                lastUpdated: 'Last Updated',
                documentCount: 'Document Count',
                viewOrder: 'View Order',
                viewDocument: 'View Document',
            },
            returnDocumentLibrary: 'Back to document library',
            returnOrderDetails: 'Back to order details',
        },
        message: {
            addTitleToMatter: 'Please add this title to a matter in order to purchase documents',
            downloadPdf: 'Download official copy PDF',
            oldTitleRegister: 'The Title Register was issued over 3 months ago',
            underInvestigation: 'This document is currently under investigation by HMLR',
            viewDocument: 'Click to view',
            viewDocumentBackdated: 'Click to view (Backdated)',
            orderDocument: 'Click to order',
            orderDocumentBackdated: 'Click to order (Backdated)',
            sentInPost: 'This document has been sent in post',
            today: 'less than 24 hours ago',
            HMLR: {
                oldTitleRegisterWithDate: 'The Title Register was issued {date}',
                oldTitlePlanWithDate: 'The Title Plan was issued {date}',
            },
            LIS: {
                oldTitleRegisterWithDate: 'The Title Sheet & Plan were issued {date}',
                oldTitlePlanWithDate: 'The Title Plan was issued {date}',
            },
        },
        ordering: {
            exportResults: 'Export results',
            orderingDocumentsInProgress: 'Ordering documents...',
            generatingDownloadInProgress: 'Generating download...',
            beingInvestigatedRegisters: 'The following registers were not immediately available. HMLR will either send them via post or provide them digitally on Orbital Witness:',
            beingInvestigatedTitlePlans: 'The following title plans were not immediately available. HMLR will either send them via post or provide them digitally on Orbital Witness:',
            beingInvestigatedOC2s: 'The following copies filed were not immediately available. HMLR will either send them via post or provide them digitally on Orbital Witness:',
            sentInPostRegisters: 'The following registers will be sent in the post:',
            sentInPostTitlePlans: 'The following title plans will be sent in the post:',
            sentInPostOC2s: 'The following copies filed will be sent in the post:',
            failedRegisters: 'The following registers failed to order to order with HMLR initially, you have not been charged. We are going to retry them with HMLR, please check your Document Library for updates:',
            failedTitlePlans: 'The following title plans failed to order to order with HMLR initially, you have not been charged. We are going to retry them with HMLR, please check your Document Library for updates:',
            failedOC2s: 'The following copies filed failed to order with HMLR initially, you have not been charged. We are going to retry them with HMLR, please check your Document Library for updates:',
            downloadOrderedDocuments: 'Download {count} ordered documents',
            someDocumentsWereNotImmediatelyAvailable: 'Some documents were not immediately available.',
            bulkOrderDocumentsInCopiesFiledTab: 'You can now bulk order the underlying documents for each title in the "Copies Filed" tab.',
            bulkOrderHint: 'All documents have been requested from HMLR. You can track their progress below or close this pop-up and they will complete in the background.',
            bulkOrderSlowWarning: 'Your order is taking longer than usual. You can close this pop-up and check for your documents in the document library or on the relevant titles. If you have any questions, please message us.',
        },
        searches: {
            table: {
                headings: {
                    document: 'DOCUMENT',
                    productName: 'PRODUCT NAME',
                    titleAddress: 'TITLE / ADDRESS',
                    createdOn: 'CREATED ON',
                    orderId: 'ORDER ID',
                },
            },
        },
        export: {
            betaLabel: 'You can view all generated Title Analysis Reports here, other reports will be added soon',
            table: {
                headings: {
                    document: 'DOCUMENT',
                    date: 'GENERATED ON ',
                    actions: 'ACTIONS',
                },
                noData: 'Generate a Title Analysis Report and it will be visible here',
            },

        },
        upload: {
            betaLabel: 'This is v1 of our Upload Documents feature. You\'ll be able to do loads more with the documents you upload very soon.',
            instructions: '<p>Select one or more PDF documents to add to this matter. All documents will be uploaded to your <strong>Document Library</strong>.</p><p class="mt-4">If any <strong>Title Registers</strong> are detected, the associated titles will also be added to your matter & all features on Orbital Witness using Title Registers (e.g. Reports, Analysis) will work as normal.</p><p class="mt-4">Please note that while uploading <strong>ScotLIS Title Sheets</strong> is supported, such files are not yet enhanced by Orbital Witness.</p>',
            tooltipText: 'Please ensure you only upload documents relevant to this matter.',
            alreadyGotDocuments: 'Already got this document?',
            acceptedFileTypeErrorPdfOnly: 'Expects PDF',
            fileTooLarge: 'File too large. Max file size: {maxFileSize}',
            dropZoneText: '<span class="caption-highlight">' +
                'Drag & Drop or <span class="ow-file-uploader__labels--choose">choose files</span> to upload.' +
                '</span>',
            dropZoneTextPdfOnly: '<span class="caption-highlight">' +
                'Drag & Drop or ' +
                '<span class="ow-file-uploader__labels--choose"\n' +
                '      data-track="DOCUMENTS - choose files link on the file upload dialog">choose files' +
                '</span> to  upload.<br />' +
                '<span class="caption-regular">(Only PDF documents)</span>' +
                '</span>',
            tooManyFiles: 'Only one file can be uploaded at a time. Please remove some of the uploaded files.',
            uploadDocuments: 'Upload Documents',
            table: {
                headings: {
                    document: 'DOCUMENT',
                    date: 'UPLOADED DATE',
                    user: 'UPLOADED BY',
                    status: 'STATUS',
                    actions: 'ACTIONS',
                },
                noData: 'Click Upload Documents to add PDF documents to this matter',
            },
            status: {
                failed: 'Failed',
                pending: 'Pending upload...',
                processing: 'Processing...',
                invalid: 'invalid file type',
                virus: 'may contain a virus',
                duplicate: 'duplicate file',
                corrupted: 'corrupted file',
                success: 'Uploaded',
            },
            documentType: {
                titleRegister: 'Register',
                titlePlan: 'Plan',
                officialCopy: 'Official Copy',
                other: 'Other',
                detecting: 'Detecting',
                cautionTitle: 'Caution Title',
                scotLISTitleSheet: 'ScotLIS Title Sheet',
            },
            notifications: {
                processing: 'Document upload in progress',
                completedSuccessfully: '1 file uploaded | {count} files uploaded',
                completeWithErrors: '1 file failed to upload | {count} files failed to upload',
                titlesAddedToMatter: '1 title detected and added to the matter | {count} titles detected and added to the matter',
                scotLISTitleSheetError: 'Some ({count}) uploaded documents were ScotLIS Title Sheets. Please note that while you can upload these, they are not yet enhanced by Orbital Witness.',
            },
        },
        tar: {
            notifications: {
                processing: "Title Analysis Report generation in progress. File will be downloaded automatically. Alternatively, it can be found in the exported documents tab in the documents view",
                complete: "Your Title Analysis Report has been successfully generated and should now be downloading",
            },
        },
        viewer: {
            downloadBtnTooltip: 'Download official copy PDF',
            viewOverlay: 'View Overlay',
            overlayPlan: 'Overlay Plan',
            fitToWidth: 'Fit to width',
            fitToHeight: 'Fit to height',
        },
        pageSelection: {
            pageNumber: 'Page {pageNumber}',
        },
    },
    report: {
        action: {
            generateReport: 'Generate report',
            seeReportingOptions: 'See reporting options',
            exportAs: 'Export as',
            export: 'Export',
            viewMoreTemplates: 'View all templates',
            exportBtnTooltip: 'Generate a report from this title',
            exportBtnWithPdfTooltip: 'Generate a report from this title / download as PDF',
            exportAsPdf: 'Download as PDF',
        },
        message: {
            generatingReport: 'Generating report',
        },
        error: {
            processReports: 'Unable to process reports as none were added to the checkout, or they have no titles selected',
            generateReport: 'Failed to generate report: {message}',
            tooLong: 'Your document is taking longer than usual to generate.',
        },
        modals: {
            success: {
                title: 'Report successfully generated',
                text: 'Your document should start downloading. ' +
                    'If the download hasn\'t started yet, then please ',
            },
            failure: {
                title: 'Oops, something went wrong!',
                text: 'Unfortunately, there was a problem while generating your export.',
            },
            generating: {
                title: 'Generating report',
                text: 'Your document should start downloading. If the download hasn\'t started yet, please ',
                extraTime: 'Your document is taking longer than usual to generate.',
            },
            asyncGeneration: {
                title: 'Report generation started',
                text: 'Your document generation request has been started. The document will be automatically downloaded when the report is generated. Alternatively, it can be found in the exported documents tab in the documents view',
            },
            ordering: {
                text: 'Ordering missing title registers',
            },
            exporting: {
                unsupportedDataProviderTooltipText: 'Reporting on titles from {0} is not supported at this time',
            },
        },
        card: {
            whatIsThis: 'What is this?',
            downloadPreview: 'Download Preview',
            viewPreview: 'View Preview',
            registerInterest: 'Register interest',
            interestRegistered: 'Interest registered, the organisation admin will have to confirm the addition of this to your account.',
            noAccess: 'Your organisation does not have access',
            copilotDescription: '<ul><li>Cut up to 70% of your time spent on due diligence by using our new real-estate specific legal AI assistant</li></ul>',
            copilotLeaseReportDescription: '<ul><li>A long form lease report designed to cover all of the key provisions in a standard commercial lease</li><li>Use your Lease documents already purchased in Orbital Witness</li></ul>',
            copilotLocalAuthorityReportDescription: '<ul><li>A report on the key disclosures identified from a local authority search, including local land charges where included in a search result</li></ul>',
            goToCopilot: 'Go to Orbital Copilot',
            generateReport: 'Generate Report',
            selectCopilotReport: 'Alternatively, select an Orbital Copilot Report',
            copilot: 'Orbital Copilot',
        },

    },
    reviewAssistant: {
        document: {
            orderedFrom: 'Documents ordered from',
            editionDate: 'Edition date: (Available after order)',
            orderPlan: 'Order Plan (£3)',
            orderBackdatedPlan: 'Order Plan (Backdated) (£3)',
        },
        titlePlan: {
            helpText: 'There can sometimes be disparities between the Map boundary and Title Plan, to learn more click this icon',
            helpLink: 'https://intercom.help/orbital-witness/en/articles/9681384-my-title-plan-boundary-and-boundary-on-the-orbital-witness-map-don-t-match-why',
        },
    },
    snapshots: {
        preview: {
            title: 'Export assistant - Preview',
        },
        title: {
            title: 'Title',
            showTitle: 'Show title',
            showSubtitle: 'Show subtitle',
            titleEditPrompt: 'Click to edit title',
            subtitleEditPrompt: 'Click to edit subtitle',
        },
        layout: {
            title: 'Layout',
            custom: 'Original Screen size',
            a3: 'A3 Paper size',
            a4: 'A4 Paper size',
            hd: 'HD Screen size',
            fourk: '4K Screen size',
        },
        orientation: {
            title: 'Orientation',
            landscape: 'Landscape',
            portrait: 'Portrait',
        },
        map: {
            preview: {
                hint: 'Move and zoom your map below, add any additional detail with the actions on the right',
            },
            key: {
                showKey: 'Key',
                configDescription1: 'Label the colours present on the map below.',
                configDescription2: 'Drag the key to reposition it on the map.',
                configHint: 'Note: Empty fields will not show on the export',
                addKeyItem: 'Add key item',
                newKeyItemName: 'Item {count}',
                addColoursFromMap: 'Add colours from map',
                labelPlaceholder: 'Label...',
                reset: 'Reset',
            },
            scale: {
                title: 'Map scale',
                description: 'Include a scale bar or export the map to a given scale (page size layouts only).',
                showScaleBar: 'Scale bar',
                showScaleText: 'Scale text',
                unknown: 'Unknown',
                disclaimer: 'Assumes printed map area measures {width}mm by {height}mm.',
                mapScale: 'Map scale (1:x)',
                mapScaleHint: 'Scale 1:',
            },
        },
        actions: {
            defaultFilename: 'Orbital Witness site plan',
            downloadPdf: 'Download PDF',
            downloadImage: 'Download Image',
        },
        generatingExport: 'Generating export...',
    },
    hmlr: {
        outOfHours: 'Outside of Land Registry operating hours.',
    },
    map: {
        options: {
            mapLayers: 'Map Layers',
            hmlrLayers: 'HMLR Layers',
            scotlandLayers: 'Scotland Layers',
            baseMap: 'Base map',
            titleNumbers: 'Title Numbers',
            freeholds: 'Freeholds',
            leaseholds: 'Leaseholds',
            scotland: 'INSPIRE Polygons',
            unregisteredLand: 'Unregistered Land',
            pocUnregisteredLand: 'Unregistered Land',
            displayLayers: 'Display Layers',
            highways: 'Highway Maintenance',
            rightOfWay: 'Public Rights of Way',
            backdrop: 'Backdrop',
            os: 'Ordnance Survey',
            osText: 'Select the Ordnance Survey map',
            osLight: 'Ordnance Survey Light',
            osLightText: 'Select the Ordnance Survey light coloured map',
            aerial: 'Aerial',
            aerialText: 'Select the aerial view map',
            siteVisit: 'Site Visit',
            downloadText: 'Download {0}',
            listedBuildings: {
                text: 'Listed Buildings',
                hover: {
                    name: 'Name',
                    grade: 'Grade',
                    entryNumber: 'Entry number',
                    dateFirstListed: 'Date first listed',
                    source: 'Source',
                },
            },
            conservationAreas: {
                text: 'Conservation Areas',
                hover: {
                    name: 'Name',
                    lpa: 'Local Planning Authority',
                    designationDate: 'Designation date',
                    updatedDate: 'Updated date',
                    source: 'Source',
                },
                conservationArea: 'Conservation Area',
                noDataForLPA: 'No data for LPA',
            },
            scheduledMonuments: {
                text: 'Scheduled Monuments',
                hover: {
                    name: 'Name',
                    entryNumber: 'Entry Number',
                    dateFirstListed: 'Date first listed',
                    source: 'Source',
                },
            },
            dno: {
                text: 'Distribution Network Operator',
                hover: {
                    siteName: 'Site name',
                    siteType: 'Site type',
                    siteVoltage: 'Site voltage',
                    source: 'Source',
                    status: 'Status',
                    voltage: 'Voltage',
                },
            },
            flood: {
                text_zone_2: 'Flood Zone 2',
                text_zone_3: 'Flood Zone 3',
                text: 'Flood Risk',
                hover: {
                    layer: 'Layer',
                    type: 'Type',
                    source: 'Source',
                },
                attribution: {
                    englandAndWales: 'Flood Zone data - © Defra - November 2023, © NRW - December 2023',
                },
            },
            flood_risks: {
                text_sea_and_rivers: 'Flood Risk - Rivers and Sea',
                text_surface_water: 'Flood Risk - Surface Water',
                hover: {
                    risk: 'Risk',
                    type: 'Type',
                    source: 'Source',
                },
            },
            environmental: {
                text_ancient_woodland: 'Ancient Woodland',
                text_aonb: 'National Landscapes / Areas of Outstanding Natural Beauty',
                text_common_land: 'Common Land',
                text_radon: 'Radon',
                text: 'Environmental',
                hover: {
                    aonb: {
                        name: 'Name',
                        designationDate: 'Designation date',
                        source: 'Source',
                        caption: 'Click to view further details',
                    },
                    commonLand: {
                        name: 'Name',
                        localAuthority: 'Local Authority',
                        source: 'Source',
                    },
                    ancientWoodland: {
                        name: 'Name',
                        type: 'Type',
                        source: 'Source',
                    },
                    sssi: {
                        name: 'Name',
                        source: 'Source',
                    },
                    radon: {
                        name: 'Name',
                        source: 'Source',
                    },
                },
                source: {
                    england: 'Natural England',
                    wales: 'Natural Resources Wales',
                },
                attribution: {
                    aonb: 'National Landscapes / Areas of Outstanding Natural Beauty data',
                    commonLand: 'Common Land data',
                    ancientWoodland: 'Ancient Woodland',
                    sssi: 'Sites of Special Scientific Interest (SSSI)',
                    radon: 'Radon Gas',
                },
            },
            unregistered_land: {
                text_unregistered_land: 'Unregistered Land',
                text_cn: 'Caution Against First Registration',
            },
            legend: {
                highways: [
                    'Maintainable at public expense',
                    'Not maintained at public expense',
                    'Maintenance responsibility is to another highway authority',
                    'Prospectively maintainable at public expense',
                    'Street outside scope of EToN',
                    'Others',
                ],
                prow: [
                    'Pedestrian way or footpath',
                    'Bridleway',
                    'Byway open to all traffic',
                    'Restricted byway',
                    'Cycle track or cycle way',
                    'All vehicles',
                ],
                listedBuildings: [
                    'Grade I',
                    'Grade II*',
                    'Grade II',
                ],
                scheduledMonuments: [
                    'Scheduled Monument',
                ],
                dno: [
                    '6.6 kv',
                    '11 kv',
                    '33 kv',
                    '66 kv',
                    '132 kv',
                    'Others',
                ],
                floodZones: [
                    'Flood Zone 2',
                    'Flood Zone 3',
                    'Flood Risk - River & Sea',
                    'Flood Risk - Surface Water',
                ],
                environmental: [
                    'National Landscapes / Areas of Outstanding Natural Beauty (AONB)',
                    'Common Land',
                    'Ancient Woodland',
                    'Sites of Special Scientific Interest (SSSI)',
                    'Radon',
                ],
            },
            rollover: {
                editSketch: 'Click to edit the Sketch',
                viewFullListEntry: 'Click to view the full list entry',
            },
        },
        downloadPopup: {
            downloadPng: 'Image',
            downloadPdf: 'PDF',
            isp: 'Interactive Site Plan',
            ispDescription: 'Clients can use this link to access a read-only view of your matter.',
            activateLink: 'Activate Link',
            generateLink: 'Generate Link',
            chooseText: 'Choose a way to share the {0} externally.',
            createHighResPlan: ' Add a key to your plan',
            createHighResPlanDescription: 'Enhance your plan by adding a key, great for client exports.',
            createHighResPlanLink: 'Add Key',
        },
        zoom: {
            zoomIn: 'Zoom in',
            zoomOut: 'Zoom Out',
            pressAndHold: 'press and hold to {direction} in small increments',
        },
        rollover: {
            clickToView: 'Click map to view',
            viewAll: 'Select fewer map layers to view all information',
            other: 'Other',
            environmental: {
                category: 'Environmental',
                source: {
                    england: 'Natural England',
                    wales: 'Natural Resources Wales',
                    bgs: 'British Geological Survey',
                },
                extended: {
                    name: 'Name',
                },
                sssi: {
                    primary: 'Sites of Special Scientific Interest',
                },
                'areas-of-natural-beauty': {
                    primary: 'National Landscapes / Areas of Outstanding Natural Beauty',
                },
                'common-land': {
                    primary: 'Common Land',
                },
                'ancient-woodland': {
                    primary: 'Ancient Woodland',
                },
                'radon-layer': {
                    primary: 'Radon',
                },
            },
            flood: {
                category: 'Flood Risk',
                source: {
                    england: 'Environment Agency',
                    wales: 'Natural Resources Wales',
                },
                risk: {
                    very_low: 'Very Low',
                    low: 'Low',
                    medium: 'Medium',
                    high: 'High',
                },
                extended: {
                    type: 'Type',
                    risk: 'Risk',
                },
                'flood-zone-2': {
                    primary: 'Flood Zone 2',
                },
                'flood-zone-3': {
                    primary: 'Flood Zone 3',
                },
                'flood-risk-sea-and-rivers': {
                    primary: 'Flood Risk - Rivers and Sea',
                },
                'flood-risk-surface-water': {
                    primary: 'Flood Risk - Surface Water',
                },
            },
            'conservation-areas': {
                category: 'Conservation Areas',
                extended: {
                    lpa: 'LPA',
                    designationDate: 'Designation Date',
                    updatedDate: 'Updated Date',
                },
            },
            'scheduled-monuments': {
                category: 'Scheduled Monuments',
                extended: {
                    entryNumber: 'Entry Number',
                    listedDate: 'Date First Listed',
                },
            },
            'listed-buildings': {
                category: 'Listed Buildings',
                extended: {
                    grade: 'Grade',
                    entryNumber: 'Entry Number',
                    listedDate: 'Date First Listed',
                },
                source: {
                    england: 'Historic England',
                    wales: 'Cadw',
                },
            },
            'public-rights-of-way': {
                category: 'Public Rights of Way',
                source: 'Ordnance Survey',
                extended: {
                    type: 'Type',
                },
            },
            'highways-maintenance': {
                category: 'Highways Maintenance',
                source: 'Ordnance Survey',
                extended: {
                    name: 'Name',
                    authority: 'Authority',
                    responsibility: 'Type',
                },
            },
            'unregistered-land': {
                category: 'Unregistered Land',
                source: 'OW & HM Land Registry',
                extended: {
                    baseType: 'Unregistered Land',
                    cn: 'Caution Against First Registration',
                },
            },
            scotland: {
                category: 'Scotland INSPIRE Polygons',
                source: 'The Scottish Government',
            },
            nps: {
                freeholds: 'Freeholds',
                leaseholds: 'Leaseholds',
                other: 'Other',
                source: 'HM Land Registry',
                category: 'Titles',
            },
            sketches: {
                clickText: 'Click map to edit sketch',
                category: 'Sketches',
                extended: {
                    sketchType: 'Type',
                },
            },
            overlays: {
                clickText: 'Click map to edit overlay',
                category: 'Overlays',
            },
        },
    },
    matter: {
        titleAdded: 'Title has already been added to the current matter',
        invalidCode: 'The code contains invalid characters, please use standard alphanumeric characters.',
        invalidCodeFormatWithoutHint: 'The matter code doesn\'t match the required format.',
        invalidCodeFormat: 'The matter code doesn\'t match the required format ({format}).',
        mandatoryMatterCode: 'A matter code must be provided ({format}).',
        mandatoryMatterCodeWithoutHint: 'A matter code must be provided.',
        mandatoryClientCode: 'A client code must be provided.',
        addressOwnerGrid: {
            additionalOwners: '+{count} other owner | +{count} other owners',
            additionalAddresses: '+{count} other address | +{count} other addresses',
        },
        success: {
            updateOwner: 'Successfully updated the owner of the matter: {matter}',
        },
        error: {
            addTitlesToMatter: 'Failed to add titles to matter: {message}',
            matterCreate: 'Failed to create matter: {matter}',
            updateOwner: 'Failed to update owner of matter: {matter}',
        },
        uploadingDocsPanel: {
            detectingTitles: 'Orbital Witness is working on detecting titles from the files.',
            appearWhenReady: 'Your titles will appear here once they are ready to view',
            ctaDescription: 'To view your uploaded files',
            goToDocumentLibrary: 'Go to the Document Library',
        },
        addTitlesPanel: {
            heading: 'Add Titles',
            prompt: 'Start by using the search bar in the top right corner, or use one of the following features',
            faq: 'Need help? Visit our FAQ page.',
            addToGroup: 'Add to group',
            enterTitleNumbers: {
                heading: 'Enter title numbers',
                prompt: 'Add titles by entering title numbers in the text box, separated by commas / lines.',
                placeholder: 'Add title numbers...',
                addTitles: 'Add Titles',
            },
            uploadSpreadsheet: {
                heading: 'Upload spreadsheet or CSV',
                prompt: 'Upload Excel or CSV file with title numbers. Excel files should contain one title number in each cell.',
                uploadButton: 'Upload Excel or CSV file',
                addTitles: 'Add Titles',
                import: {
                    acceptedFileTypeError: 'Only .xls, .xlsx, or .csv files are currently accepted',
                    dropZoneText: '<span class="caption-highlight">' +
                        'Drag & Drop or ' +
                        '<span class="ow-file-uploader__labels--choose">choose file' +
                        '</span> to  upload.<br />' +
                        '<span class="caption-regular">(Only CSV, XLS, or XLSX files are currently accepted)</span>' +
                        '</span>',
                    instructions: '<p>Select an Excel file (1 title number per cell) or CSV file with title numbers.</p>',
                    uploadFiles: 'Upload Title Numbers',
                },
            },
            drawOnMap: {
                heading: 'Draw on map',
                prompt: 'Select titles by drawing a shape around an area of the map. Click to add new points and complete the shape.',
                tip: 'TIP: You can modify your drawing by dragging sections of it.',
                area: 'Area',
                reset: 'Reset',
                stopDrawing: 'Stop Drawing',
                startDrawing: 'Start Drawing',
                saveAsSketch: 'Save the area as a sketch',
                addTitles: 'Add Titles',
            },
            uploadShapeFile: {
                heading: 'Upload a shapefile',
                prompt: 'Select Shapefile .zip, .kmz, or .kml file',
                uploadButton: 'Upload a shapefile',
                onlyWithin: 'Import titles strictly within shape',
                onlyWithinTooltip: 'When checked, titles that only partially fall inside the drawn shape will be excluded from the import.',
                import: {
                    acceptedFileTypeError: 'Only Shapefile (.zip), .kmz, or .kml files are currently accepted',
                    dropZoneText: '<span class="caption-highlight">' +
                        'Drag & Drop or ' +
                        '<span class="ow-file-uploader__labels--choose">choose file' +
                        '</span> to  upload.<br />' +
                        '<span class="caption-regular">(Only Shapefile .zip, KMZ, or KML files are currently accepted)</span>' +
                        '</span>',
                    instructions: '<p>Select a Shapefile (each .zip should contain a .shp, .shx, .dbf, and .prj file).<br/>KML and KMZ files are also supported.</p>',
                    uploadFiles: 'Upload Shapefile',
                },
            },
            uploadTitleRegisters: {
                heading: 'Upload title register PDFs',
                prompt: 'Orbital Witness will be automatically detecting the titles from the uploaded documents',
            },
        },
        addToMatterWithCount: 'Add to matter ({count})',
        addTitles: 'Add Titles',
        addTitlesPrompt: 'You haven\'t added any titles yet.',
        addTitlesPromptSubtitle: 'Start by using the search bar in the top right corner, or by clicking on "Add titles" button.',
        addTitlesPromptFAQ: 'Need help? Visit our FAQ page.',
        filterTitlesHint: 'Filter titles by <b>title number</b>, <b>tenure</b>, <b>address</b>, <b>custom name</b> or <b>group name</b>.',
        search: 'Search by name, codes, type, and owner',
        sharedWithWholeOrganisation: 'Matter is shared with the whole organisation',
        sharedWithMultipleUsers: 'Matter is shared with {count} other users',
        sharedWithSingleUser: 'Matter is shared with one other user',
        sharedWithNoOne: 'Matter is private to you',
        storeSketch: 'Save the area as a sketch',
        linkSharingEnabled: 'Client sharing link is enabled',
        linkSharingDisabled: 'Client sharing link is disabled',
        linkSharingExpired: 'Client sharing link has expired',
        linkSharingDisabledAndExpired: 'Client sharing link is disabled and has expired',
        drawOnMapImportSketchName: 'Draw on map import - {timestamp}',
        addAll: 'Add All ({count})',
        removeAll: 'Remove All ({count})',
        selectAll: 'Select All ({count})',
        selectedCount: 'Selected ({count})',
        noRecentMatters: 'You don\'t have any recent matters, click view all or create a matter above to get started.',
        emptyGroup: 'There are no titles in this group',
        noBoundaryData: 'No boundary data available for this group',
        selection: {
            selectionOptions: 'Selection Options',
            titlesSelected: '0 titles selected | 1 title selected | {count} titles selected',
            addToGroup: 'Add to group',
            addToNewGroup: 'Add to new group',
            showSelectedBoundaries: 'Show selected boundaries',
            hideSelectedBoundaries: 'Hide selected boundaries',
            changeStyleAndName: 'Change style and name',
            purchaseOrRefreshOfficialCopies: 'Purchase/Refresh official copies',
            exportPendingApplications: 'Export pending applications',
            removeFromGroup: 'Remove from group',
            removeTitlesFromMatter: 'Remove titles from matter',
            exportAs: 'Report/Export as...',
            exportTitleBoundaries: 'Export boundaries to Shapefile',
            exportTitleAnalysisReport: 'Title Analysis Report (Excel)',
            unmergedTitleAnalysisReport: 'HighQ Title Analysis Report',
            exportCopilotReport: 'Need an Orbital Copilot report?',
            exportCopilotLeaseReport: 'Orbital Copilot long form lease report',
            exportCopilotLocalAuthorityReport: 'Orbital Copilot local authority search report',
        },
        prompts: {
            createGroup: {
                title: 'Create a new Matter Group',
                body: 'A matter group is used to group titles together.',
            },
            updateGroup: {
                title: 'Update group name',
            },
            deleteGroup: {
                title: 'Delete group',
                body: 'Are you sure you want to delete this group?',
                query: 'Also remove associated titles from this matter',
            },
            exportOptions: {
                title: 'Export options for the selected title | Export options for {count} selected titles',
            },
            purchaseOptions: {
                title: 'Purchase options for the selected title | Purchase options for {count} selected titles',
                body: 'Select document types below that you would like to purchase',
                query: 'Please confirm charges of up to <strong>£{count}</strong> for this action',
            },
            removeTitlesFromMatter: {
                title: 'Remove titles from matter',
                body: 'Are you sure that you want to remove 1 selected title from the matter? | Are you sure that you want to remove {count} selected titles from the matter?',
            },
            reopen: {
                title: 'Reopen this matter',
                body: 'Anyone with access to the matter will be able to make changes and may incur charges.',
            },
            close: {
                title: 'Close this matter',
                body: 'This will prevent any further charges being incurred on the matter. Users will still be able to access any documents ordered in the matter, but will not be able to make changes or view the matter. You can reopen the matter at any time.',
            },
        },
        titleOrganiser: {
            name: 'Title Organiser',
            quickStyling: 'Quick Styling',
            quickStylingDescription: 'Automatically styles your title boundaries, based on their grouping.',
            groupTitlesBy: 'Group titles by',
            current: {
                name: 'Current',
                description: 'Retain the current matter grouping',
            },
            owner: {
                name: 'Owner',
                description: 'Group by proprietor',
            },
            tenure: {
                name: 'Tenure',
                description: 'Group by tenure type',
            },
            advanced: {
                name: 'Advanced Grouping',
                description: 'Group by any of the columns in our Analysis feature',
            },
            complexity: {
                name: 'Title Complexity',
                description: 'Group by Orbital Witness’s title complexity',
            },
            confirmChanges: 'Confirm Changes',
            confirmChangesDescription: 'Please confirm you wish to save the current groupings, styling and order?',
            confirmChangesWarning: 'Any old groupings, styling and order cannot be recovered once confirmed.',
            unknownGroupName: 'Unknown',
            unknownRegisterNotOwnedGroupName: 'Unknown - Register Not Owned',
            unknownParentTitleNotOwnedGroupName: 'Unknown - Parent Title Not Owned',
            otherGroupName: 'Other',
            optionsPanel: {
                header: 'Advanced Grouping',
            },
        },
        titleComplexity: {
            extremelySimple: 'Extremely Simple',
            verySimple: 'Very Simple',
            fairlySimple: 'Fairly Simple',
            fairlyComplex: 'Fairly Complex',
            veryComplex: 'Very Complex',
            extremelyComplex: 'Extremely Complex',
        },
        share: {
            title: 'Share',
            internal: 'Internally',
            external: 'Externally',
            showOptions: 'Share Options',
            hideOptions: 'Hide Share Options',
            shareMatter: {
                title: 'Share the {sharingType} outside your organisation',
                subtitle: 'With the public link, anyone with the link can view this {sharingType}. This is a read only access.',
            },
            copyLinkToClipboard: 'Click to copy link to clipboard',
            preview: 'Preview',
            createLink: 'Create a shareable link',
            isp: {
                title: 'Interactive site plan',
                subtitle: 'Clients can use this link to access a read-only view of your interactive map.',
            },
            walkthrough: {
                title: 'Interactive walkthrough',
                subtitle: 'Easily highlight key features & issues within your map to your client.',
            },
            expiryDate: 'Expiry Date (Optional)',
            expiryDateDescription: 'If provided, the link will only work prior to this date, and any visitor will be informed thereafter that it is no longer active.',
            password: 'Password protected (optional)',
            passwordDescription: 'You can change this password to something else at a later date, but you will not be able to recover it.',
            passwordOverwrite: 'New password (will overwrite existing password)',
            passwordOptional: 'Optional password required to access the link',
        },
    },
    matterSettings: {
        actions: {
            duplicateMatter: 'Duplicate Matter',
        },
        duplicateMatterDialog: {
            title: 'Duplicate Matter',
            newMatterNameCaption: 'New Matter Name',
            newMatterCodeCaption: 'New Matter Code',
            content: 'Duplicating a matter will create a new matter and copy:',
            confirm: 'Duplicate Matter',
            duplicationFinishedMessage: 'Your matter was successfully duplicated!',
            goToNewMatter: 'Go to new matter',
            fieldIsRequired: 'This field is required',
            duplicatedItems: {
                titles: 'Titles',
                groups: 'Groups',
                sketches: 'Sketches',
                userAccess: 'User Access',
            },
        },
        copilotAutoSyncDialog: {
            title: 'Auto-sync with Copilot',
            description: 'Auto-sync your matter documents with Orbital Copilot and generate custom reports with AI, purpose-built for property diligence.',
        },
    },
    tam: {
        templates: {
            allRegisterEntries: {
                name: 'All Register Entries',
                description: 'Every register entry against each title with classification and date.',
            },
            overseasProprietor: {
                name: 'Overseas Proprietor',
                description: 'Groups titles by whether they are owned by an overseas entity.',
            },
            defectiveFreehold: {
                name: 'Defective Freehold',
                description: 'All freehold titles not having class of title being Title Absolute.',
            },
            defectiveLeasehold: {
                name: 'Defective Leasehold',
                description: 'All leasehold titles not having class of title being Title Absolute.',
            },
            expiringLeases: {
                name: 'Expiring Leases',
                description: 'All leasehold titles sorted by remaining lease term.',
            },
            location: {
                name: 'Location',
                description: 'All titles grouped by location.',
            },
            chargedOnly: {
                name: 'Charged Only',
                description: 'Only the titles that have been charged.',
            },
            freeholdTitle: {
                name: 'Freehold Title',
                description: 'Only the freehold titles.',
            },
            leaseholdTitle: {
                name: 'Leasehold Title',
                description: 'Only the leasehold titles.',
            },
            restrictions: {
                name: 'Restrictions',
                description: 'Only the titles containing restrictions.',
            },
            proprietorsAndChargeHolders: {
                name: 'Proprietors And Charge Holders',
                description: 'All titles\' proprietors and charge holders.',
            },
            titlesWithMoreThanOneAddress: {
                name: 'Titles With More Than One Address',
                description: 'Only the titles containing more than one address.',
            },
            landRemovedFromTitle: {
                name: 'Land Removed From Title',
                description: 'Only the titles where land has been removed.',
            },
            proprietorsAndLeaseInfo: {
                name: 'Proprietors and Lease Info',
                description: "All titles' proprietors and lease information",
            },
            companiesHouse: {
                name: 'Companies House',
                description: 'Easily check Companies House information for a portfolio of titles.',
            },

        },
        columnGroups: {
            basic: 'Basic',
            owner: 'Ownership',
            lease: 'Lease Information',
            flags: 'Flags',
            restrictions: 'Restrictions',
            other: 'Other',
        },
        filter: {
            showAll: 'Show all',
            showPurchased: 'Show owned',
            showUnpurchased: 'Show unpurchased',
        },
    },
    titlePanel: {
        filter: 'Filter',
        createGroup: 'Create Group',
        searchFor: 'Search for “{value}”',
        uploadedDocumentWarning: 'This information is generated from an uploaded file.',
        outOfSyncDocs: 'Data may be out of sync. Reorder the title register to refresh.',
        registerEntries: 'Register Entries',
        header: {
            actions: {
                addToMatter: 'Add To Matter',
                orderRegister: 'Order Register ({cost})',
                openRegister: 'Open Register',
                goToMatterMap: 'Go to Matter Map',
                changeStyleAndName: 'Change Style and Name',
            },
        },
        tabs: {
            summary: 'Summary',
            register: 'Register',
            officialCopies: 'Official Copies',
            leaseHierarchy: 'Leases',
            dayList: 'Pending Applications',
            planning: 'Planning',
            nearby: 'Nearby',
            epc: 'EPCs',

            sections: {
                summary: {
                    overview: 'Overview',
                    ownership: 'Ownership',
                    boundary: 'Boundary',
                    searches: 'Searches',
                    registerAndPlan: 'Register & Plan',
                    complexity: 'Title Complexity',
                },
                nearby: {
                    matterTitles: 'Matter Titles',
                    corporateOwners: 'Corporate Owners',
                },
            },
        },
        cards: {
            daylist: {
                refreshing: 'Please wait while we refresh the Day List...',
                accuracy: 'Accurate as of {time} ago.',
                multipleApplications: 'There are {total} applications or searches pending against this title.',
                multipleApplicationsFlag: '{total} applications or searches are pending against this title, you' +
                    ' may wish to view their details.',
                noApplications: 'No applications or searches pending against this title.',
                noApplicationsError: 'There was an error with HMLR, please try again by refreshing the Day List.',
                singleApplication: 'There is one application or search pending against this title.',
                singleApplicationFlag: '1 application or search is pending against this title, you may wish to view' +
                    ' its details.',
                hmlrTimeout: 'There was a connection error with HMLR, please try again by refreshing the Day List.',
                scottishOrderingWarning: 'Scottish titles require a premium service to view the additional application data.',
            },
            ownership: {
                title: 'Ownership',
                labels: {
                    register: 'Title Register',
                    titleSheet: 'Title Sheet',
                    dateAdded: 'Date added',
                    incorporated: 'Country incorporated',
                    overseas: 'Overseas',
                    owner: 'Owner',
                    ownerAddress: 'Correspondence address',
                    proprietorship: 'Proprietorship',
                    regNo: 'Company reg. number',
                    companiesHouse: 'Companies House',
                    foreignCompany: 'Foreign Company',
                    gotoCompaniesHouse: 'Go to Companies House',
                    overseasEntity: 'Overseas Entity',
                    companyRegCannotBeMatched: 'Company reg number cannot be matched on Companies House',
                    overseasEntityFoundOnCH: 'Overseas Entity found on Companies House.',
                    overseasEntityNotFoundOnCH: 'Overseas Entity not found on Companies House.',
                    overseasEntityNotFound: 'Overseas Entity not found.',
                    listedOwner: 'Listed Owner',
                    companiesHouseMatchesSourceName: 'Companies House name matches name of registered proprietor.',
                    companiesHouseDoesntMatchSourceName: 'Companies House name doesn\'t match name of registered proprietor.',
                    companiesHouseOwner: 'Companies House: Listed Owner',
                    companiesOverseasEntity: 'Companies House Overseas Entity',
                    companyStatus: 'Company status',
                    companiesHouseCompanyStatus: 'Companies House: Company status',
                    companiesHouseCompanyRegisteredAddress: 'Companies House: Registered office address',
                    companiesHouseCompanyNatureOfBusiness: 'Companies House: Nature of business (SIC)',
                    companyRegisteredAddress: 'Registered office address',
                    companyNatureOfBusiness: 'Nature of business (SIC)',
                    notAvailable: 'Register required',
                    purchaseRegister: 'Privately-owned title. Purchase the register to see ownership details.',
                },
                sources: {
                    unavailable: 'Ownership details are unavailable. Please purchase the title register to view the' +
                        ' ownership information.',
                    ccod: 'Source: Land Registry - Corporate and Commercial Ownership Data (CCOD). Accurate as of {date}.',
                    ocod: 'Source: Land Registry - Overseas Companies Ownership Data (OCOD). Accurate as of {date}.',
                    register: 'Source: {icon} Title Register, Edition Date: {date}.',
                    titleSheet: 'Source: {icon} Title Sheet, Edition Date: {date}.',
                    companiesHouse: 'Source: {icon} Companies House, accurate as of {date}',
                    companiesHouseNoDate: 'Source: {icon} Companies House',
                },
                flags: {
                    ccodOcodMismatch: 'The registered owner in your Title Register doesn’t match the registered owner in the latest HMLR monthly corporate ownership data. You may wish to refresh your Title Register to get the latest version.',
                },
            },
            complexity: {
                title: 'Title Complexity',
                labels: {
                    complexity: 'Complexity',
                    complexityDescription: 'Complexity Description',
                    complexityDescriptionTooltip: 'The complexity of a title is determined by the number of titles, the number of proprietors, and the number of charges.',
                    complexityDescriptionTooltipLink: 'Learn more about title complexity',
                    purchaseRegister: 'Purchase the register to see title rating, copies filed and flags.',
                    rating: 'Rating',
                    copiesFiled: 'Copies Filed',
                    flags: 'flags',
                    ratingInfo1: `Title Complexity helps you see what you're dealing with at the outset.
                                Orbital Witness instantly compares every title you download against tens of thousands of
                                titles to give you a quick
                                and accurate benchmark of how complex the title is on a six point scale:`,
                    ratingInfo2: `The rating looks at a range of data points about the title
                                taking into account both the volume of content to review,
                                and the number of "unusual" aspects of a title.`,
                },
            },
            epc: {
                title: 'Energy Performance Certificate (EPC)',
                subtitle: 'EPCs matched ({ length }): We match our EPCs on UPRN data, there may be more EPCs for this title which cannot be matched via UPRN, please search on the {link} EPC register.',
                epcTooLowTextNonDomestic: '1 non-domestic EPC has a rating of F or G. | {count} non-domestic EPCs have a rating of F or G.',
                epcTooLowTextDomestic: '1 domestic EPC has a rating of F or G. | {count} domestic EPCs have a rating of F or G.',
                expiredNonDomesticCount: '1 non-domestic EPC has expired | {count} non-domestic EPCs have expired.',
                expiredDomesticCount: '1 domestic EPC has expired | {count} domestic EPCs have expired.',
                expiredDisplayCount: '1 display EPC has expired | {count} display EPCs have expired.',
                source: 'Source: EPC Open Data Communities Last updated: {date}',
                noData: 'No EPCs matched via UPRN for this title.',
                noEpcChart: "There's no EPC chart for this title.",
                expired: 'Expired',
                epcAddress: 'EPC Address',
                expiryDate: 'Expiry Date',
                issuedOn: 'Issued On',
            },
            flags: {
                uploadBetaTooltip : 'Orbital Witness has made best efforts to ensure accuracy of Flags for Uploaded Registers, but for guaranteed flag counts, please order the register.',
            },
            basicInformation: {
                noAddress: 'Please load the digital title to view address information.',
                title: 'Overview',
                intersectsUnregisteredLand: 'Intersects with unregistered land: ',
                yes: 'Yes',
                no: 'No',
            },
            boundary: {
                loadingMatterBoundaries: 'Loading matter boundaries',
                showMatterBoundaries: 'Show matter boundaries',
            },
        },
        findNearby: {
            matterTitlesHeading: 'Nearby Titles',
            matterTitlesSubheading: 'Found {resultCount} result(s) within {distance}m of {titleNumbers} that are in' +
                ' other matters within your organisation. Consisting of {distinctTitles} title(s) within' +
                ' {distinctMatters} matter(s).',
            corporateOwnersHeading: 'Corporate Owners',
            corporateOwnersSubheading: 'Found {resultCount} corporate owner(s) within {distance}m of {titleNumbers}.',
            matterTitles: {
                columns: {
                    title: 'Title',
                    inMatter: 'In Matter',
                    inGroup: 'In Group',
                },
            },
            error: 'An error occurred while fetching nearby titles. Please try again.',
        },
        owCopilotBlocked: {
            submitButtonText: 'OK',
            content: 'Orbital Copilot is currently experimental and can only deal with 16 Legal Documents at a time. Please try again with a simpler Title.',
            title: 'Orbital Copilot Unavailable',
            noRegister: 'Order the register to use Orbital Copilot',
            noDocuments: 'No documents selected for Orbital Copilot',
            noDocumentsToSend: 'No documents available for Orbital Copilot',
            uploadedRegister: 'Orbital Copilot does not yet support uploaded registers. Purchase the register to use Orbital Copilot.',
        },
        leaseHierarchy: {
            owner: 'Owner: | Owners:',
            title: 'Lease Hierarchy',
            loading: 'Please wait while we load the lease hierarchy...',
            disclaimer: 'This Lease Hierarchy is created with information from HM Land Registry, accurate as of {ocdaDate}, and populated with ownership, and tenure information provided {hmlrMonthlyDataDate}.',
            noData: 'No lease hierarchy data available for this title.',
            cafrMessage: 'Unable to display lease hierarchy for this title - Caution Against First Registration.',
            hierarchyRemoved: '<strong>Why did the whole Lease Hierarchy just clear?</strong> Orbital Witness uses HMLR data to generate the Lease Hierarchy. We detect potential Freeholds based on the location of titles and then create a Lease Hierarchy using lease information from HMLR. Sometimes the title you are viewing is not present in that Lease Hierarchy and as a result, the generated Hierarchy is cleared as this title isn’t present. If you have any <strong>feedback</strong> on this, please contact us using the chat icon in the bottom right.',
            appearsElsewhere: 'This title appears elsewhere in the hierarchy.',
        },
    },
    titles: {
        titles: 'Titles',
        unavailableBoundary: 'The title boundary is not available.',
        unavailableBoundaryWithDataSource: 'The title boundary is not currently available from {0}.',
        titleBoundaryExportFileName: 'Orbital Witness - export of {titleNumber} title boundaries | Orbital Witness - export of {count} title boundaries',
        addressUnavailable: 'Address unavailable',
        sonol: {
            title: 'Schedule of Notices of Leases',
            regDateAndPlan: 'Registration date and plan ref',
            propertyDescription: 'Property description',
            dateOfLease: 'Date of lease',
            term: 'Term',
            lesseesTitle: 'Lessee\'s title',
        },
    },
    overlays: {
        nameEditPrompt: {
            title: 'Change overlay name',
            name: 'Name',
        },
    },
    sketches: {
        spatialImportTypes: {
            areas: 'Areas',
            lines: 'Lines',
            points: 'Markers',
            text: 'Text',
        },
        import: {
            acceptedFileTypeError: 'Only Shapefile (.zip), GeoJSON, KML, and KMZ files are currently accepted',
            dropZoneText: '<span class="caption-highlight">' +
                'Drag & Drop or ' +
                '<span class="ow-file-uploader__labels--choose"\n' +
                '      data-track="SKETCHES - choose files link on the file upload dialog">choose files' +
                '</span> to  upload.<br />' +
                '<span class="caption-regular">(Only Shapefile .zip, GeoJSON, KML or KMZ files)</span>' +
                '</span>',
            instructions: '<p>Select one or more Shapefiles (each .zip should contain a .shp, .shx, .dbf, and .prj file).<br/>GeoJSON, KML, and KMZ files are also supported.</p>',
            uploadFiles: 'Import Sketches',
            tooltipText: 'Import Sketches',
        },
        exportSpatialFilename: 'Orbital Witness - export of {name} sketch | Orbital Witness - export of {count} sketches',
        strokeWidth: 'Stroke width',
        title: 'Sketches',
        map: 'Map',
        displaySketches: 'Display sketches on {link} page',
        noSketches: {
            heading: 'You haven\'t created any sketches yet.',
            subheading: 'Select a tool to get started',
            faqLinkText: 'Need help? Read our sketches guide',
            faqLinkUrl: 'https://intercom.help/orbital-witness/en/articles/7992090-how-can-i-add-more-detail-to-my-plan-with-shapes-lines-text-markers',
        },
        addSketch: 'Add Sketch',
        importSketches: 'Import Sketches',
        toolPanel: {
            heading: 'Select a tool below to create a new sketch, or select an existing sketch to edit it or add to it.',
            area: 'Area',
            areaDescription: 'Draw custom areas on the map to enhance your plan',
            linesAndArrows: 'Lines',
            linesAndArrowsDescription: 'Use lines to highlight key areas of your plan',
            text: 'Text',
            textDescription: 'Annotate your plan with text labels',
            marker: 'Marker',
            markerDescription: 'Place markers on the map to highlight key features',
        },
        selection: {
            sketchesSelected: '0 sketches selected | 1 sketch selected | {count} sketches selected',
            hideSelectedSketches: 'Hide selected sketch | Hide selected sketches',
            showSelectedSketches: 'Show selected sketch | Show selected sketches',
            hideLabels: 'Hide label | Hide labels',
            showLabels: 'Show label | Show labels',
            changeLabelAndDescription: 'Change label and description',
            importTitles: 'Import titles using sketch',
            exportAs: 'Export as...',
            deleteSketches: 'Delete sketch | Delete sketches',
        },
        snapSketchTo: 'Snap sketch to:',
        snapTo: {
            none: 'None',
            sketches: 'Other sketches',
            titleBoundaries: 'My title boundaries',
        },
        areaHint: 'Click on the map to add points to define an area.',
        areaHintWithProgress: 'To finish your sketch, click <strong>Done</strong> or press <strong>Enter / Escape</strong> or close the area by clicking the first point.',
        areaHintWithExisting: 'Click on the map to draw an additional Area with the same style in {sketchName}.',
        lineHint: 'Click on the map to add points to define a line.',
        lineHintWithProgress: 'To finish your line sketch, use <strong>double click</strong> for the final point, or click <strong>Done</strong> or press <strong>Enter / Escape</strong> when finished.',
        lineHintWithExisting: 'Click on the map to draw an additional Line with the same style in {sketchName}.',
        markerHint: 'Click on the map to add a marker.',
        markerHintWithProgress: 'To finish your marker sketch click <strong>Done</strong>.',
        markerHintWithExisting: 'Click on the map to add an additional marker with the same style in {sketchName}.',
        textHint: 'Click on the map to add text.',
        textHintWithProgress: '',
        textHintWithExisting: 'Click on the map to add an additional <strong>Text</strong> with the same style in <strong>{sketchName}</strong>. To finish your text sketch click <strong>Done</strong> or press <strong>Escape</strong> to finish.',
        advancedOptions: 'Advanced options',
        nameDescriptionPrompt: {
            title: 'Change label and description',
            name: 'Label',
            description: 'Description',
        },
        empty: 'Empty',
        settings: 'Sketches Settings',
        titleImport: 'Sketches Title Import Settings',
        settingModal: {
            unitOfMeasurement: 'Unit of measurement',
            displaySketchesOnMap: 'Display sketches on Map page',
            unitOfMeasureOptions: [
                {
                    text: 'Metric (metres / kilometres)',
                    value: 0,
                },
                {
                    text: 'Imperial (acres / miles / yards)',
                    value: 1,
                },
                {
                    text: 'Hectares (hectares)',
                    value: 2,
                },
            ],
        },
    },
    style: {
        style: 'Style',
        selectStyle: 'Select style',
        stroke: 'Stroke',
        solidOrDashed: 'Solid or dashed',
        colour: 'Colour',
        backgroundColour: 'Background colour',
        textColour: 'Text Colour',
        textSolid: 'Solid or outline',
        arrowhead: 'Arrowhead',
        fill: 'Fill',
        size: 'Size',
        showLabel: 'Show label',
    },
    comingSoon: 'Coming Soon',
    admin: {
        organisation: {
            add: 'Add organisation',
            edit: 'Edit organisation',
            sections: 'Organisation sections',
            addSection: 'Add organisation',
            editSection: 'Edit organisation',
            licenceOverview: 'Licence overview',
            rules: {
                onlyAlpha: 'Only alphanumeric characters (accents included), allowed special characters (, . & - ( ) \') and spaces are allowed for the organisation name.',
            },
            form: {
                tabs: {
                    general: 'General',
                    billing: 'Billing',
                    chargesEmail: 'Charges email',
                    features: 'Features',
                    products: 'Products',
                    grandfathered: 'Grandfathered',
                },
            },
            label: {
                maximumAllocatedUsers: 'Maximum user accounts to be allocated',
                maximumPremiumAllocatedUsers: 'Maximum Premium Seats',
                maximumStandardAllocatedUsers: 'Maximum Standard Seats',
                defaultSectionName: 'Default section name',
                defaultSectionAddress: 'Default section address',
                defaultSectionHouseBuildingNumber: 'Default section house/building number',
                defaultSectionStreetName: 'Default section street name',
                defaultSectionLocality: 'Default section locality',
                defaultSectionTown: 'Default section town',
                defaultSectionPostcode: 'Default section postcode',
                defaultSection: 'Default section',
                pricingModel: 'Pricing model',
                registerPrice: 'Price for loading title register and title summary',
                titleSummaryCharge: 'Title summary charge',
                pmpm: 'Price per active user per matter per month',
                ppc: 'PPC standard charge',
                billingEmail: 'Billing email',
                withoutColumnHeadersCsvCharges: 'Prefer charges CSV without column headers',
                hmlrCsvCharges: 'Prefer charges CSV in HMLR format',
                fullNameInChargesEmail: "Prefer user's full name in charges email",
                officeDefaultSectionName: "Use default section name for 'Office' column",
                matterCodeSeparator: 'Matter code separator (e.g. /)',
                hideableMatterPanel: 'Hideable matter panel',
                usesClientCodes: 'Uses client codes',
                mandatoryMatterCodes: 'Mandatory matter codes',
                matterCodeFormat: 'Matter code format',
                matterCodeMask: 'Matter code mask',
                clientCodeFormat: 'Client code format',
                ssoOption: 'SSO Option',
                ssoProviderId: 'SSO Provider ID',
                emailDomains: 'Email domains',
                sectionName: 'Section name',
                addressHouseNumber: 'House/building number',
                addressStreet: 'Street name',
                addressLocality: 'Locality',
                addressTown: 'Town',
                addressPostcode: 'Postcode',
                canShareMattersWithWholeOrg: 'Can share matters with whole organisation',
                displayingMatters: 'Displaying 0 matters | Displaying 1 matter | Displaying {count} matters',
                searchUsers: 'Search for users in your organisation',
                planning: 'Planning',
                findNearby: 'Find Nearby',
                shapefileExport: 'Shapefile Export',
                activeUsers: 'Active users',
                totalLicences: 'Total licences',
                liteLicences: 'Lite licences',
                standardLicences: 'Standard licences',
                premiumLicences: 'Premium licences',
            },
            hint: {
                billingEmail: 'Email address to send billing emails to, use comma separated for multiple addresses.',
                matterCodeSeparator: 'One or more characters to separate the client and matter codes in the charges email.',
                emailDomains: "Enter a domain (do not include {'\"@\"'} ) and press enter (e.g. orbitalwitness.com).",
                matterCodeFormat: 'This will be presented as a hint to the user when creating a new matter.',
                matterCodeMask: 'A regular expression used to validate the matter code.',
            },
            organisationCreateError: 'There was an error creating the organisation. Check that the details are valid. {errorMessage}',
            organisationSectionCreateError: 'There was an error creating the organisation section. Check that the details are valid. {errorMessage}',
        },
        user: {
            label: {
                filterByOrganisation: 'Filter by organisation',
                filterByStatus: 'Filter by status',
                filterByRole: 'Filter by role',
                filterByLicence: 'Filter by licence',
                addUser: 'Add user',
                editUser: 'Edit user',
                emptyTable: 'No organisation selected, or no users in this organisation',
                noProducts: 'No products available',
                selectOrganisation: 'Please select an organisation.',
                systemAdminAccount: 'System admin account',
                organisationAdminAccount: 'Organisation admin account',
                create: 'There was an error creating the user. Check that the details are valid',
                invalidEmailDomain: 'Email address must match the valid domains ({validDomains})',
                activeAccount: 'Licence is active',
                usage: 'Currently using { activeCount } active accounts of { totalCount } available. ({ inactiveCount } inactive accounts.)',
                usagePremium: 'Currently using { premiumCount } premium accounts of { totalPremiumCount } available.',
                userCount: 'Nothing | Showing 1 user for the currently selected filters. | Showing {count} users for the currently selected filters.',
                passwordHint: 'At least 8 characters, and at least one number, one uppercase, and one non alpha-numeric character',
            },
            hint: {
                systemAdminAccount: 'Only OW users can be system admins',
            },
            error: {
                createAccount: 'There was an error creating the user account. Check that the details are valid.',
                noUsers: 'No users selected. Remove any filters that may be applied.',
            },
            tooltips: {
                refresh: 'Click to refresh the list of users.',
                download: 'Click to download CSV file of users.',
                newUser: 'Click to add a new user.',
                inactive: 'This account is inactive',
                premium: 'This is a premium user',
            },
        },
        log: {
            maxResultsInfo: 'Showing first {maxResults} results. Export as CSV to include more.',
        },
        messages: {
            nightlyCharges: 'Test nightly charges emails',
            emailSentSuccess: 'If a valid email address was specified, it should be receiving the nightly emails for charges incurred on the given date shortly.',
            emailSentError: 'An error was encountered when sending the emails.',
        },
    },
    form: {
        label: {
            name: 'Name',
            industry: 'Industry',
            status: 'Status',
            address: 'Address',
            organisation: 'Organisation',
            section: 'Section',
            email: 'Email',
            password: 'Password',
            firstName: 'First name',
            lastName: 'Last name',
            phoneNumber: 'Phone number',
            timeRange: 'Time range',
            sendTo: 'Send to',
            chargesDate: 'Charges date',
            owner: 'Owner',
            regions: 'Regions',
            defaultLocale: 'Default language',
        },
        hint: {
            singleEmail: 'Single email',
        },
        error: {
            invalidOwner: 'Please select a different owner',
        },
    },
    table: {
        label: {
            name: 'Name',
            document: 'Document',
            email: 'Email',
            organisation: 'Organisation',
            lastSeen: 'Last seen',
            licenceType: 'Licence',
            registered: 'Registered',
            role: 'Role',
            roles: 'Roles',
            active: 'Active',
            group: 'Group',
            title: 'Title',
            type: 'Type',
            status: 'Status',
            documentDate: 'Document date',
            downloadDate: 'Download date',
            private: 'Private',
            specificUsers: 'Specific Users',
            createdBy: 'Created by',
            clientCode: 'Client code',
            matterCode: 'Matter code',
            lastAccessed: 'Last accessed',
            sharedWith: 'Shared with',
            createdOn: 'Created on',
            matterType: 'Type',
        },
    },
    searchBar: {
        corporateOwnershipUpdated: 'Corporate ownership data accurate as of {date}',
    },
    callouts: {
        sketchesFirstSketch: 'Choose whether your sketches will be displayed on the map page here',
    },
    companiesHouse: {
        ownerText: {
            mismatchText: 'Owner does not match on',
            matchText: 'Owner matches on',
            ownerMatches: 'Owner matches on Companies house',
            matchedOverseasEntityText: 'Overseas Entity found on',
            matchedOverseasEntityTextV2: 'Overseas Entity found on Companies House',
            mismatchedOverseasEntityText: 'Not found on Companies House',
            searchForOverseasEntity: 'Search for Overseas Entity on Companies House',
        },
        buttons: {
            search: 'Search on Companies House',
            link: 'Companies House ({companyRegNumber})',
        },
        flags: {
            name: 'The registered company name on Companies House doesn’t match the name from {source}, you may wish to confirm the correct name.',
            status: 'The company status is {status}, you may wish to check if the company is still Active.',
            matched: 'The registered proprietor is incorporated outside of the UK. We have identified an Overseas Entity with the same name registered at Companies House, implying this entity has registered on the Register of Overseas Entities (ROE).',
            notMatched: 'The registered proprietor is incorporated outside of the UK. We cannot identify an Overseas Entity with the same name registered at Companies House, implying this company has not registered on the Register of Overseas Entities (ROE). You may wish to check the ROE directly.',
        },
    },
    mapOverlays: {
        title: 'Overlay Plans',
        actions: {
            confirmDocument: 'Confirm Document',
            confirmPage: 'Confirm Page',
        },
        stepFlow: {
            step1: {
                title: 'Select a document',
                description: 'Choose a document to overlay on the map',
            },
            step2: {
                title: 'Select a page',
                description: 'Choose a page from the selected document',
                setPlanScale: 'Set Plan Scale',
                setPlanScaleHint: 'Please set the scale of this page. You\'ll be able to change the scale on the next step so don\'t worry if you can\'t see it in the preview below.',
            },
            step3: {
                title: 'Overlay plan',
                description: 'Position the plan over the map',
            },
        },
        planScale: 'Plan scale',
        loadingDocument: 'Loading document',
        loadingOverlay: 'Loading overlay',
        loadingOverlayTakingALongTime: 'Please wait whilst our system creates your overlay image for you',
        step1: {
            uploadDocument: {
                title: 'Upload Document',
                instructions: 'Already own the document? Upload it so that it can be overlaid on the map.',
            },
        },
        panel: {
            backToOverlaidPlans: 'Back to Overlaid Plans',
            hintText: 'Overlay plans on top of the interactive map and compare document and digital boundaries.',
            hintText2: 'Still have questions? {link}',
            hintText2LinkLabel: 'Read FAQ',
            hintText2Link: 'https://intercom.help/orbital-witness/en/articles/overlays',
            newOverlay: 'New Overlay',
            position: 'Position',
            positionHint: 'Move your overlaid plan by dragging it around the map',
            sketchHint: 'Need to add a Sketch?',
            sketchHintDescription: 'Annotate the map with custom sketches, markers, labels and arrows.',
            sketchHintButtonLabel: 'Add Sketch',
            editPlan: 'Edit Plan',
            pageNumber: 'Page {pageNumber}',
            panToPlan: 'Pan to plan',
            planToMe: 'Bring plan to me',
            editPage: 'Edit Page',
            removePlan: 'Remove overlaid plan',
            page: 'Page',
            transparency: 'Transparency',
            lastUpdated: 'Last updated',
            planScale: 'Plan scale',
            rotation: 'Rotation',
        },
        selection: {
            title: 'Selection Options',
            overlaysSelected: '0 overlays selected | 1 overlay selected | {count} overlays selected',
            hideSelectedOverlays: 'Hide selected overlay | Hide selected overlays',
            showSelectedOverlays: 'Show selected overlay | Show selected overlays',
            removeOverlays: 'Remove overlay | Remove {count} overlays',
            renameOverlay: 'Rename overlay',
        },
        mapRollOver: {
            name: 'Overlaid Plan',
        },
    },
    searches: {
        title: 'Searches',
        description: 'Using our Searches feature, you can order & receive your searches directly in Orbital Witness',
        orderSearches: 'Order Searches',
        orderSearchesDraft: 'Draft Order',
        orderSearchesDescription: 'You can order & receive your searches directly in Orbital Witness',
        filterProducts: 'Filter Products',
        filterUsers: 'Filter on users in your organisation',
        selectProduct: 'Select Product',
        swapProduct: 'Swap Product',
        noProductsAvailable: 'No products available',
        noProductsSelected: 'The basket is empty',
        availableProducts: 'Available',
        availableProductsDescription: 'These products are not included in your order',
        ordered: '{value} • {status}',
        unableToOrderSearches: 'Unable to order searches',
        invalidAddressTMG: 'A UPRN was not available for the provided address and currently cannot be used for searches when using the {provider} provider.',
        showMatchingHazards: 'Show matching hazards',
        productsSelected: '1 product selected | {count} products selected',
        provider: 'Provider',
        productPreference: 'Search Template',
        propertyType: 'Property Type',
        contact: {
            title: 'Is there a problem with your order?',
            TmGroup: {
                label: 'Contact TM Group',
            },
            SearchFlow: {
                label: 'Contact SearchFlow',
            },
        },
        intro: {
            title: 'Welcome to Searches',
            description: 'Using our Searches feature, you can order & receive your searches directly in Orbital Witness via TM Group, using existing titles & boundaries already in your matter, allowing you to keep more things in one place and easily monitor the progress of orders.',
            registerInterestText: 'Existing TM Group customers are able to order Searches at no extra cost! If you\'d like to use it, please click on the button below to register your interest, and we\'ll be in touch.',
            registerInterestCta: 'I\'m interested!',
            registerInterestCtaThanks: 'Thank you for registering your interest. We will be in touch soon.',
            footer: 'Click the button below to get started.',
            cta: 'Start a search',
        },
        columns: {
            name: 'Product / Provider',
            provider: 'Provider',
            expectedDate: 'Expected Date',
            cost: 'Cost',
            returnedDate: 'Delivered Date',
            status: 'Status',
            hazard: 'Hazards / Category',
        },
        createOrder: {
            fields: {
                titleNumber: 'Title number',
                address: 'Address',
            },
            flow: {
                step1: {
                    title: 'Define Boundary',
                },
                step2: {
                    title: 'Define Address',
                },
                step3: {
                    title: 'Add Products',
                },
                step4: {
                    title: 'Setup Notifications',
                },
            },
            orderConfirmation: 'Please confirm that you would like to order the search',
            orderConfirmationTitle: 'Order Confirmation',
            invalidBasket: 'Some products are incomplete',
            invalidProducts: 'Mandatory parameters not selected',
            mandatory: 'Mandatory parameter',
            invalid: 'Invalid parameter',
            na: 'N/A',
            saveDraft: 'Save Draft',
            savingDraft: 'Saving Draft',
            updateDraft: 'Update Draft',
            confirmBoundary: 'Confirm Boundary',
            confirmAddress: 'Confirm Address',
            confirmDetails: 'Confirm Details',
            saveAlerts: 'Save Alerts',

        },
        promoCards: {
            overlay: {
                title: 'Overlay Searches',
                description: 'Overlay your search results on the map to easily identify any issues with your property.',
                cta: 'Start a search',
            },
            copilot: {
                title: 'Analyse with Orbital Copilot',
                description: 'Send your search documents to Orbital Copilot, allowing you to generate a report on your searches and quickly identify any issues.',
                cta: 'Orbital Copilot',
            },
        },
        searchFlow: {
            problemWithOrder: 'Is there a problem with your order?',
            contact: 'Contact SearchFlow',
        },
        titleList: {
            title: 'Order Searches',
            subtitle: 'Select titles or draw a custom boundary to define your search area on the map',
            searchesBoundary: 'Searches Boundary',
            area: 'Area: {area}',
            filter: '({selected} of {count}) Selected',
        },
        map: {
            stopDrawing: 'Stop Drawing',
            drawBoundary: 'Draw Boundary',
            editBoundary: 'Edit Boundary',
            boundaryDisconnected: 'Single Boundary Required',
            boundaryDisconnectedDescription: 'A single boundary is required to order searches. Please connect the boundaries or remove unwanted areas.',
            clickToEditBoundary: 'Click the button below to edit the boundary',
        },
        enterAddressForm: {
            titlesWithAddresses: 'Selected titles with addresses ({num})',
            title: 'Specify the address of the search',
            organisationName: 'Organisation Name',
            buildingNumber: 'Building Number',
            thoroughfareName: 'Street',
            postTown: 'Town',
            postcode: 'Postcode',
            sector: 'Sector',
        },
        projectNameForm: {
            required: 'Required',
            invalid: 'Must conform to the project mask set by your organisation: {projectMask}',
            projectName: 'Project Reference',
        },
        productConfig: {
            title: 'Product Configuration',
            editConfig: 'Edit',
            additionalOptions: 'Additional Options',
            additionalOptionsAvailable: 'Additional options available',
            additionalOptionsSelected: 'Additional options selected',
            addOptionalEnquiries: 'Add optional enquiries',
            included: 'Included',
            con29Dwc: {
                expedited: 'Expedited',
                lessThan10Years: 'Less than 10 years',
                developerName: 'Developer Name*',
                offSiteRoadName: 'Off Site Road Name*',
                siteNameAndPhase: 'Site Name and Phase*',
                fields: {
                    expedited: '<span class="caption-highlight">Expedited: </span>{0}',
                    lessThan10Years: '<span class="caption-highlight">Less than 10 years: </span>{0}',
                    developerName: '<span class="caption-highlight">Developer name: </span>{0}',
                    offSiteRoadName: '<span class="caption-highlight">Offsite road name: </span>{0}',
                    siteNameAndPhase: '<span class="caption-highlight">Site name & phase: </span>{0}',
                },
            },
            tmgLLC1: {
                numberOfParcels: 'Number of parcels',
                fields: {
                    numberOfParcels: '<span class="caption-highlight">Number of parcels: </span>{0}',
                },
            },
            highwaysRa: {
                searchPurpose: 'Search Purpose',
                fields: {
                    searchPurpose: '<span class="caption-highlight">Search Purpose: </span>{0}',
                },
            },
            highways: {
                searchPurpose: 'Search Purpose',
                fields: {
                    searchPurpose: '<span class="caption-highlight">Search Purpose: </span>{0}',
                },
            },
            argSSCombo: {
                currentSiteUse: 'Current site use*',
                proposedSiteUse: 'Proposed site use*',
                yearsInCurrentUse: 'Years in current use*',
                additionalConsiderations: 'Additional considerations',
                plannedDevelopment: 'Planned development',
                fields: {
                    currentSiteUseField: '<span class="caption-highlight">Current site use: </span>{0}',
                    proposedSiteUseField: '<span class="caption-highlight">Proposed site use: </span>{0}',
                    yearsInCurrentUseField: '<span class="caption-highlight">Years in current use: </span>{0}',
                    additionalConsiderationsField: '<span class="caption-highlight">Additional considerations: </span>{0}',
                    plannedDevelopmentField: '<span class="caption-highlight">Planned development: </span>{0}',
                },
            },
            con29: {
                fields: {
                    additionalRoads: '<span class="caption-highlight">Additional roads: </span>{0}',
                    additionalQuestions: '<span class="caption-highlight">Additional questions: </span>{0}',
                    additionalEnquiries: '<span class="caption-highlight">Additional enquiries: </span>{0}',
                },
                standardEnquiries: {
                    title: 'Standard enquiries (CON29R)',
                    roadSearch: {
                        searchAdditionalRoads: 'Search additional roads',
                        roadName: 'Road',
                        usrn: 'USRN',
                        roadPlaceholder: 'Road {index}',
                    },
                },
                additionalQuestions: {
                    title: 'Include additional questions',
                    placeholder: 'Question {index}',
                    question: 'Question',
                },
                additionalEnquiries: {
                    title: 'Additional enquiries (CON29O)',
                    indicators: {
                        "RoadProposalsByPrivateBodiesIndicator": "4. Road proposals by private bodies",
                        "AdvertisementsIndicator": "5. Advertisements",
                        "CompletionNoticesIndicator": "6. Completion notices",
                        "ParksAndCountrysideIndicator": "7. Parks and countryside",
                        "PipelinesIndicator": "8. Pipe lines",
                        "HousesInMultipleOccupationIndicator": "9. Houses in multiple occupation",
                        "NoiseAbatementIndicator": "10. Noise abatement",
                        "UrbanDevelopmentAreasIndicator": "11. Urban development areas",
                        "EnterpriseZonesIndicator": "12. Enterprise zones/local development orders/BIDS",
                        "InnerUrbanImprovementAreasIndicator": "13. Inner urban improvement areas",
                        "SimplifiedPlanningZonesIndicator": "14. Simplified planning zones",
                        "LandMaintenanceNoticesIndicator": "15. Land maintenance",
                        "MineralConsultationAreasIndicator": "16. Mineral consultations/safeguarding",
                        "HazardousSubstanceConsentsIndicator": "17. Hazardous substance consents",
                        "EnvironmentAndPollutionNoticesIndicator": "18. Environmental and pollution notices",
                        "FoodSafetyNoticesIndicator": "19. Food safety notices",
                        "HedgerowNoticesIndicator": "20. Hedgerow notices",
                        "FloodDefenceAndLandDrainageConsentsIndicator": "21. Flood defence and land drainage consents",
                        "CommonLandTownAndVillageGreensIndicator": "22. Common land and town and village greens",
                    },
                },
            },
        },
        notifications: {
            title: 'Searches Notifications',
            table: {
                columns: {
                    name: 'Name',
                    email: 'Email',
                    enabled: 'Enabled',
                    providerUserId: 'Provider User ID',
                    providerEmailNotification: 'Email',
                },
                header: {
                    title: 'Users with notifications ({count})',
                    description: 'Setup email notifications for your organisation',
                },
            },
        },
        order: {
            tabs: {
                orderDetails: 'Order Details',
                setupNotifications: 'Setup Notifications',
            },
            alerts: {
                newOrder: '<p class="caption-highlight">A new search order has been created.</p><p>Please set up email notifications to ensure you receive important updates and information about this order.</p>',
            },
            header: {
                title: 'Products ({count})',
                description: 'Products that have been added to the Searches order.',
            },
        },
        error: {
            title: 'Searches Error',
            default: 'A searches error occurred. Please try again.',
            SEARCHES_FETCH_CATEGORIES: 'An error occurred while fetching the categories.',
            SEARCHES_FETCH_SEARCH_PROVIDERS: 'An error occurred while fetching the search providers.',
            SEARCHES_FETCH_PRODUCT_PREFERENCES: 'An error occurred while fetching the product preferences.',
            SEARCHES_FETCH_DRAFT_ORDER_DETAILS: 'An error occurred while fetching the draft order details.',
            SEARCHES_INIT_CONTEXT: 'An error occurred while initializing the context.',
            SEARCHES_ADD_TO_BASKET: 'An error occurred while adding the product to the basket.',
            SEARCHES_ADD_PRODUCTS_TO_BASKET: 'An error occurred while adding the products to the basket.',
            SEARCHES_REMOVE_FROM_BASKET: 'An error occurred while removing the product from the basket.',
            SEARCHES_PREPOPULATE_BASKET: 'An error occurred while prepopulating the basket.',
            SEARCHES_POPULATE_BASKET_FROM_DRAFT_ORDER: 'An error occurred while populating the basket from the draft order.',
            SEARCHES_FETCH_PRODUCT_QUOTE: 'An error occurred while fetching the product quote.',
            SEARCHES_QUOTE: 'An error occurred while quoting the products.',
            SEARCHES_UPDATE_DRAFT_ORDER: 'An error occurred while updating the draft order.',
            SEARCHES_SUBMIT_DRAFT_ORDER: 'An error occurred while submitting the draft order.',
            cta: {
                retry: 'Retry',
                copyToClipboard: 'Copy to clipboard',
                returnToMap: 'Return to Map',
            },
        },
    },
    unauthorised: {
        title: 'No access to Orbital Witness',
        paragraph1: 'Your account does not have access to Orbital Witness. This app can be used to locate, order, analyse and visualise the property data you need to advise clients, all in one place.',
        paragraph2: 'If you believe you should have access, you can contact our support team using the button below. Alternatively, if you’re looking to access Orbital Copilot (which your account does have access to), please click the button below.',
    },
    pageTitles: {
        overlays: 'Overlays',
        unauthorised: 'Unauthorised User',
    },
    copilot: {
        cta: {
            documentViewer: {
                title: 'Generate lease report',
                description: 'Use Orbital Copilot to speed up your due diligence.',
            },
        },
        banner: {
            title: 'Orbital Copilot',
            description: 'Use Orbital Copilot to speed up your due diligence.',
            cta: "Select",
            documentLibrary: {
                title: 'Use Orbital Copilot to speed up due diligence',
                description: 'Analyse documents and generate reports fast with Orbital Copilot',
                openCopilot: 'Send Documents to Orbital Copilot ({count})',
                openCopilotNoSelection: 'Send Documents to Orbital Copilot',
            },
        },
        documentSelection: {
            noDocsSelected: 'Select at least 1 document to proceed',
            filterDocumentsHint: 'Filter documents by <b>title number</b>, <b>type</b>, or <b>group name</b>.',
            tableHeaders: {
                docName: 'Document Name',
                type: 'Type',
                title: 'Title',
                group: 'Group',
                docDate: 'Doc Date',
            },
            ctaHeader: {
                title: 'Analyse with Orbital Copilot',
                description: 'Please select the documents you want Orbital Copilot to analyse.',
            },
        },
    },
    appNav: {
        copilot: {
            title: 'Orbital Copilot',
            subtitle: 'Generate customisable reports with AI purpose-built for property diligence.',
        },
        witness: {
            title: 'Orbital Witness',
            subtitle: 'Locate, analyse and visualise property and title data.',
        },
    },
    quickActions: {
        applyTemplate: {
            title: 'Apply Template',
            description: 'Apply a template to view the relevant column data.',
        },
        exportCurrentView: {
            title: 'Export Current View',
            description: 'Export the currently visible columns to an Excel file.',
        },
        downloadTAR: {
            title: 'Title Analysis Report',
            description: 'Export title features organised in separate sheets',
            disabled: 'Please purchase title registers in order to export data',
        },
        highQExport: {
            title: 'HighQ Title Analysis Report',
            description: 'Report compatible with HighQ (rows are not merged by title number)',
        },
    },
    assetMonitoring: {
        navigationHeadings: {
            titles: 'Titles',
            companies: 'Companies',
        },
        actions: {
            viewAlerts: 'View Alerts',
            manageMonitors: 'Manage Monitors',
        },
        text: {
            description: 'Alerts are generated when there is a monitored change in your matter.' +
                ' You can configure what is monitored in your matter in the {link} section.',
            updatesReceived: 'Received 1 new alert. Click the refresh button to load the latest. | ' +
                'Received {count} new alerts. Click the refresh button to load the latest.',
            source: 'Source:',
            noData: 'No alerts',
            noDataInfo: 'This matter is being monitored, but no alerts have been received yet. Alerts will appear here when changes are' +
                ' detected.',
        },
        dataGrid: {
            label: {
                date: 'Date',
                details: 'Details',
                from: 'From',
                to: 'To',
                label: 'Label',
            },
            ownership: {
                name: 'Company Name',
                address: 'Address',
                status: 'Status',
                number: 'Company Number',
                natureOfBusiness: 'Nature of Business',
            },
        },
        type: {
            titleRegisterUpdated: {
                title: 'Title Register Updated',
                description: "Title register for title {titleNumber} is out of date.",
                shortDescription: "Register edition updated",
            },
        },
        readStatus: {
            showUnreadOnly: 'Show unread only',
        },
        subType: {
            daylist: {
                title: 'Changes found in pending applications',
                addedApplications: '1 application was added | {count} applications were added',
                finalisedApplications: '1 application was finalised | {count} applications were finalised',
                finalisedApplicationsTooltip: 'This could mean that they were either completed or cancelled.',
                updatedApplications: '1 application was updated | {count} applications were updated',
            },
            pendingApplicationsUpdated: {
                title: 'Pending Applications Updated',
                description: "The pending applications for title {titleNumber} have changed.",
                applicationReference: 'Application Reference',
                customerReference: 'Customer Reference',
            },
            oc2DocumentUpdated: {
                title: 'Official Copies Updated',
                description: "The official copies for title {titleNumber} have changed since the last register was purchased.",
                subTitle: 'Official copies {action} since last register purchased',
                newDocument: 'New <strong>{type}</strong> available',
                updatedDocument: 'Updated <strong>{type}</strong> available',
            },
            EditionDateDoesntMatchOcdaResponse: {
                title: 'Edition Date Mismatch',
                description: 'The edition date for the title {titleNumber} has changed since the last register was purchased.',
            },
            ownershipUpdated: {
                title: 'Ownership Updated',
                description: "The ownership information for title {titleNumber} has changed.",
                previous: "Previous Owner",
                new: 'New Owner',
                added: 'Owner Added',
                removed: 'Owner Removed',
            },
            boundaryUpdated: {
                title: 'Boundary Updated',
                description: "The area of the boundary for {titleNumber} has changed, the new boundary is {size} {isLarger}:",
                boundary: "Boundary",
                larger: 'larger',
                smaller: 'smaller',
                key: 'Key',
                boundaryChangeReduced: 'Boundary Changed (Reduced)',
                boundaryChangeAdded: 'Boundary Changed (Added)',
                newBoundary: 'New Boundary',
                previousBoundary: 'Previous Boundary',
            },
            companiesHouseUpdated: {
                title: 'Companies House Updated',
                description: 'The Companies House information for title {titleNumber} has changed',
            },
        },
        preferences: {
            instructions: 'Select what is monitored across this matter, an alert will be sent for any monitored changes.',
            cards: {
                presets: {
                    cardTitle: 'Preference Presets',
                    cardDescription: 'Presets are a fast way to customise what preferences are selected.',
                    owRecommendedTitle: 'Orbital Witness (recommended)',
                    owRecommendedDescription: 'Default preferences',
                },
                titleDetails: {
                    cardTitle: 'Monitoring preferences',
                    cardDescription: 'Receive alerts when changes are detected in your matter.\n',
                    trackDaylistTitle: 'Pending Applications Changes',
                    trackDaylistDescription: 'Receive alerts when pending applications are added, updated, or finalised. This data is' +
                        ' refreshed hourly.',
                    trackOcdaTitle: 'Official Copies Changes',
                    trackOcdaDescription: 'Receive alerts when new or updated official copies are available. This data is refreshed hourly.',
                    trackOwnershipTitle: 'Ownership Changes',
                    trackOwnershipDescription: 'Receive alerts when changes are detected in HMLR ownership data. This data is refreshed' +
                        ' monthly.',
                    trackBoundaryTitle: 'Boundary Changes',
                    trackBoundaryDescription: 'Receive alerts when changes are detected in the boundary data. This data is refreshed' +
                        ' monthly.',
                    dailySummaryEmailEnabledTitle: 'Daily Summary Email',
                    dailySummaryEmailEnabledDescription: 'Receive a daily email letting you know of any alerts from the last 24 hours.',
                    trackCompaniesHouseTitle: 'Companies House Changes',
                    trackCompaniesHouseDescription: 'Recieve alerts when changes are detected in companies house data. This data is refreshed hourly.',
                },
            },
        },
        toaster: {
            heading: 'New monitoring alert received',
            action: 'Go to <strong>Monitors</strong> to see the details.',
            boundaryUpdatedMessage: 'An update to the boundary of a title in this matter has been detected.',
            pendingApplicationsUpdatedMessage: 'An update to the pending applications for a title in this matter has been detected.',
            ocdaUpdatedMessage: 'An update to the official copies for a title in this matter has been detected.',
            ownershipUpdatedMessage: 'Updates to the ownership of a title in this matter has been detected.',
            companiesHouseUpdatedMessage: 'Updates to the Companies House data of a title in this matter has been detected.',
        },
        splashPage: {
            title: 'Monitoring',
            description: 'Orbital Witness can monitor and alert you when there are updates to any title within your matter. This feature can help you ' +
                ' stay on top of your matter and creates a timeline based log of all updates.',
            canMonitor: 'Monitors currently check for changes in:',
            monitorOptions: {
                ownership: 'Ownership changes',
                pendingApplications: 'Pending application changes',
                boundary: 'Boundary changes',
            },
        },
        dataSource: {
            HMLR: 'HMLR',
            companiesHouse: 'Companies House',
        },
    },
    dialog: {
        registerInterest: {
            title: 'Register interest',
            confirmation: 'Thank you for registering your interest in our product.',
            ctaInTouch: 'We will be in touch soon',
        },
        removeDocumentFromMatter: {
            title: 'Remove document from matter',
            confirmation: 'Are you sure you would like to remove this document from your matter?',
        },
        user: {
            userDetails: "User details",
            organisationDetails: "Organisation*",
            products: "Products",
            userSettings: "User Settings",
        },
    },
    licencing: {
        licenceType: "Licence Type",
        tooltipTitle: "{feature} is a Premium feature",
        cardText: "Please contact support to discuss upgrading your account to access the feature, or speak to your organisation administrator (If your organisation has spare {licence} licences)",
        tooltip: "Please contact support to discuss upgrading your account to access the feature",
        features: {
            alerts: 'Alerts',
            planning: 'Planning',
            nearby: 'Nearby',
            shapefileExport: 'Shapefile Export',
        },
    },
}
