<template>
    <section class="title-panel-card title-complexity"
             data-section="complexity">
        <div class="title-panel-card__content">
            <h2 v-t="'titlePanel.cards.complexity.title'"
                class="label-caps-medium ownership__content--title" />

            <unpurchased-register v-if="!isRegisterAvailable"
                                  :is-loading="isLoading"
                                  label-path="titlePanel.cards.complexity.labels.purchaseRegister" />

            <template v-else>
                <div class="title-complexity__row title-complexity__row--include-border"
                     data-test="title-complexity">
                    <title-complexity-rating :is-loading="isLoading"
                                             :matter-id="matterId"
                                             :selected-title-number="selectedTitleNumber" />

                    <title-complexity-copies-filed :is-loading="isLoading"
                                                   :copies-filed-documents="copiesFiledDocuments" />
                </div>
                <div class="title-complexity__row">
                    <title-complexity-flags :is-loading="isLoading"
                                            :linked-indicators="linkedIndicators"
                                            data-test="title-complexity-flags-data"
                                            @set-selected-tab="emit('set-selected-tab', $event)" />
                </div>
            </template>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        markRaw,
        onMounted,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwIconTitleComplexityExtremelyComplex from '@/components/core/icons/ow-icon-title-complexity-extremely-complex.vue'
    import OwIconTitleComplexityExtremelySimple from '@/components/core/icons/ow-icon-title-complexity-extremely-simple.vue'
    import OwIconTitleComplexityFairlyComplex from '@/components/core/icons/ow-icon-title-complexity-fairly-complex.vue'
    import OwIconTitleComplexityFairlySimple from '@/components/core/icons/ow-icon-title-complexity-fairly-simple.vue'
    import OwIconTitleComplexityVeryComplex from '@/components/core/icons/ow-icon-title-complexity-very-complex.vue'
    import OwIconTitleComplexityVerySimple from '@/components/core/icons/ow-icon-title-complexity-very-simple.vue'
    import TitleComplexityCopiesFiled from '@/components/title-panel/v2/cards/title-complexity-copies-filed.vue'
    import TitleComplexityFlags from '@/components/title-panel/v2/cards/title-complexity-flags.vue'
    import TitleComplexityRating from '@/components/title-panel/v2/cards/title-complexity-rating.vue'
    import UnpurchasedRegister from '@/components/title-panel/v2/cards/unpurchased-register.vue'
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { ITitlePanelTabController } from '@/composables/use-title-panel'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import { checkFlag } from '@/feature-flags'
    import { ITitleComplexity } from '@/interfaces/title/complexity.interface'
    import {
        FETCH_COMPLEXITY_FOR_TITLES,
        GET_TITLE_COMPLEXITY,
    } from '@/store/modules/complexity/types'
    import { COMPLEXITY } from '@/utils/title-enums'

    const emit = defineEmits<{
        (e: 'set-selected-tab', tabIndex: TitlePanelTabName),
    }>()

    const props = defineProps<{
        isLoading?: boolean,
        tabController: ITitlePanelTabController,
        matterId: Number,
    }>()

    props.tabController.addTabSection(TitlePanelTabName.Summary, {
        id: 'complexity',
        order: 2,
    })

    const store = useStore()
    const { t } = useI18n()
    const {
        selectedTitleNumber,
        isRegisterAvailable,
        copiesFiledDocuments,
        linkedIndicators,
    } = useSelectedTitle()

    const isDebugModeEnabled = computed<boolean>(() => checkFlag('allow-debug-mode', true))

    // TITLE COMPLEXITY SCORE
    const titleComplexity = ref<ITitleComplexity>(null)
    const getTitleComplexity = async () => {          // Request the latest complexity data for the title.
        await store.dispatch(`complexity/${ FETCH_COMPLEXITY_FOR_TITLES }`, {
            matterId: props.matterId,
            titleNumbers: [ selectedTitleNumber.value ],
            debugMode: isDebugModeEnabled.value,
        })
        titleComplexity.value = store.getters[`complexity/${ GET_TITLE_COMPLEXITY }`](selectedTitleNumber.value)
    }

    const complexityScoreCategory = computed<string>(() => {
        return titleComplexity.value?.score?.category ?? ''

    })
    const complexityCSSClass = computed<string>(() => {
        if (!props.isLoading) {
            return `title-complexity__category--${ complexityScoreCategory.value?.toLowerCase() }`
        }
        return ''
    })
    const complexityScoreCategoryText = computed(() => {
        switch (complexityScoreCategory.value?.toLowerCase()) {
            case ('extremelysimple'):
                return t('matter.titleComplexity.extremelySimple')

            case ('verysimple'):
                return t('matter.titleComplexity.verySimple')

            case ('fairlysimple'):
                return t('matter.titleComplexity.fairlySimple')

            case ('fairlycomplex'):
                return t('matter.titleComplexity.fairlyComplex')

            case ('verycomplex'):
                return t('matter.titleComplexity.veryComplex')

            case ('extremelycomplex'):
                return t('matter.titleComplexity.extremelyComplex')

            default:
                return ''

        }
    })
    const complexityScoreValue = computed<string>(() => {
        if (titleComplexity.value?.score?.value) {
            return `${ titleComplexity.value?.score.value }/${ COMPLEXITY.MAX_SCORE }`
        }
        return ''
    })
    const complexityIcon = computed<any>(() => {
        switch (complexityScoreCategory.value?.toLowerCase()) {
            case ('extremelysimple'):
                return markRaw(OwIconTitleComplexityExtremelySimple)

            case ('verysimple'):
                return markRaw(OwIconTitleComplexityVerySimple)

            case ('fairlysimple'):
                return markRaw(OwIconTitleComplexityFairlySimple)

            case ('fairlycomplex'):
                return markRaw(OwIconTitleComplexityFairlyComplex)

            case ('verycomplex'):
                return markRaw(OwIconTitleComplexityVeryComplex)

            case ('extremelycomplex'):
                return markRaw(OwIconTitleComplexityExtremelyComplex)

            default:
                return null
        }
    })
    const complexityIndicators = computed(() => {
        return titleComplexity.value?.indicators ?? []
    })

    onMounted(() => {
        getTitleComplexity()
    })
</script>

<style lang="scss" scoped>
    @import './title-complexity';
</style>
