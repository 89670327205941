<template>
    <side-nav :key="sideNavKey"
              v-model="activeTabId"
              v-model:section="activeSectionId"
              :actions="actions"
              :items="tabs"
              :is-loading="isLoading"
              :scroll-element-id="scrollElementId"
              :disable-scroll-to-section="!tabController?.getOptions().scrollToSection"
              @click="emit('click', $event)"
              @scroll="emit('scroll-content', $event)">
        <v-window v-model="activeTabId"
                  :class="{
                      'flex-grow-1': !isFullScreen,
                  }">
            <v-window-item v-for="(component) in components"
                           :key="'wi_' + component.key"
                           :value="component.key"
                           reverse-transition="fade-transition"
                           transition="fade-transition"
                           :eager="component.eager">
                <component :is="component.component"
                           :key="'cmp_' + component.key"
                           :class="{
                               'has-warning': isRegisterUploaded,
                           }"
                           :current-matter-id="matterId"
                           :is-caution-against-first-registration="isCautionAgainstFirstRegistration"
                           :is-full-screen="isFullScreen"
                           :is-loading="isLoading"
                           :is-ordering-allowed="isOrderingAllowed"
                           :is-register-purchased="isRegisterPurchased"
                           :is-register-uploaded="isRegisterUploaded"
                           :official-copies="officialCopiesAvailability"
                           :map="map"
                           :order-with-checkout="orderWithCheckout"
                           :scroll-element-container="`#${scrollElementId}`"
                           :selected-title="selectedTitle"
                           :selected-title-number="selectedTitleNumber"
                           :selected-title-numbers="[selectedTitleNumber]"
                           :show-title-complexity="showTitleComplexity"
                           :tab-controller="tabController"
                           :target-map="map"
                           :title-charge="titleSummaryCharge"
                           @set-selected-tab="tab => $emit('set-selected-tab', tab)"
                           @title-number-selected="redirectToTitle" />
            </v-window-item>
        </v-window>
    </side-nav>
</template>

<script setup lang="ts">
    import {
        computed,
        markRaw,
        onMounted,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import { useStore } from "vuex"

    import SideNav from "@/components/side-nav/side-nav.vue"
    import CopiesFiledTab from "@/components/title-panel/v2/copies-filed-tab/copies-filed-tab.vue"
    import DaylistEnquiry from "@/components/title-panel/v2/daylist/daylist-enquiry.vue"
    import EpcTab from "@/components/title-panel/v2/epc/epc-tab.vue"
    import FindNearbyTab from "@/components/title-panel/v2/find-nearby/find-nearby-tab.vue"
    import LeaseHierarchyTab from "@/components/title-panel/v2/lease-hierarchy/lease-hierarchy.vue"
    import PlanningContainer from "@/components/title-panel/v2/planning/planning-container-with-map.vue"
    import RegisterTabRender from "@/components/title-panel/v2/register/register-tab-renderer.vue"
    import SummaryTabRenderer from "@/components/title-panel/v2/summary-tab/summary-tab-renderer.vue"
    import { useSelectedTitle } from "@/composables/use-selected-title"
    import useTitlePanel from "@/composables/use-title-panel"
    import { DOCUMENT_SOURCE } from "@/consts/document-source"
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"

    const store = useStore()
    const planningData = computed(() => store.state.config?.featureFlags?.planningData)
    const map = computed(() => store.state.map.map)
    const titleSummaryCharge = computed(() => store.state?.user?.titleSummaryCharge)

    const {
        boundaryAvailable,
        isCautionAgainstFirstRegistration,
        isRegisterPurchased,
        isRegisterUploaded,
        selectedTitle,
        selectedTitleNumber,
        tenure,
        titleRecordSource,
        officialCopiesAvailability,
    } = useSelectedTitle()

    const props = withDefaults(defineProps<{
        isFullScreen: boolean,
        isLoading: boolean,
        isOrderingAllowed?: boolean,
        orderWithCheckout?: boolean,
        scrollElementId: string,
        showTitleComplexity?: boolean,
        matterId: number,
    }>(), {
        showTitleComplexity: true,
        orderWithCheckout: false,
        isOrderingAllowed: true,
    })

    const {tabs, actions, tabController, actionController, activeSectionId, activeTabId} = useTitlePanel({
        isFullScreen: props.isFullScreen,
    })
    const emit = defineEmits<{
        (e: 'title-number-selected', titleNumber: string),
        (e: 'click', args: { tabId: string, sectionId: string, replace?: boolean }),
        (e: 'set-selected-tab', args: { tabId: string, sectionId: string, replace?: boolean }),
        (e: 'update:modelValue', value: TitlePanelTabName),
        (e: 'scroll-content', scrollTop: number)
    }>()

    const {t} = useI18n()

    const components = ref([])
    const sideNavKey = ref(0)

    const renderComponents = () => {
        let componentsToRender = [
            {
                key: TitlePanelTabName.Summary,
                component: markRaw(SummaryTabRenderer),
                eager: true,
            },
            {
                key: TitlePanelTabName.Register,
                component: markRaw(RegisterTabRender),
                eager: true,
            },
            {
                key: TitlePanelTabName.CopiesFiled,
                component: markRaw(CopiesFiledTab),
            },
            {
                key: TitlePanelTabName.Leases,
                component: markRaw(LeaseHierarchyTab),
            },
            {
                key: TitlePanelTabName.DayList,
                component: markRaw(DaylistEnquiry),
            },
            {
                key: TitlePanelTabName.Planning,
                component: markRaw(PlanningContainer),
            },
            {
                key: TitlePanelTabName.FindNearby,
                component: markRaw(FindNearbyTab),
            },
            {
                key: TitlePanelTabName.Epc,
                component: markRaw(EpcTab),
            },
        ]

        switch (titleRecordSource.value) {
            case DOCUMENT_SOURCE.SCOTLAND:
                // Scotland only has summary and register tabs
                componentsToRender = componentsToRender.filter(component => [TitlePanelTabName.Summary, TitlePanelTabName.Register, TitlePanelTabName.DayList].includes(component.key))
                break
        }

        components.value = componentsToRender
    }

    const redirectToTitle = (titleNumber: string) => {
        emit('title-number-selected', titleNumber)
    }

    const setDefaultTabOptions = () => {
        tabController.value.setOptions({
            scrollToSection: true,
        })
    }

    const setTabSpecificOptions = (tabId: string) => {
        switch (tabId) {
            case TitlePanelTabName.FindNearby:
                tabController.value.setOptions({
                    scrollToSection: false,
                })
                break
        }
    }

    watch(() => titleRecordSource.value, (newValue: string) => {
        if (newValue) {
            tabController.value.getTabs()
            actionController.value.getDefaultActions()
            renderComponents()
        }
    })

    watch(() => activeTabId.value, (newValue: string, oldValue: string) => {
        if (newValue !== oldValue) {
            emit('set-selected-tab', {tabId: newValue, sectionId: activeSectionId.value })

            setDefaultTabOptions()
            setTabSpecificOptions(newValue)
        }
    })

    watch(() => activeSectionId.value, (newValue: string, oldValue: string) => {
        if (newValue !== oldValue) {
            emit('set-selected-tab', {tabId: activeTabId.value, sectionId: newValue })
        }
    })

    watch(() => tabController.value, (newValue, oldValue) => {
        if (newValue !== oldValue) {
            setDefaultTabOptions()
            setTabSpecificOptions(activeTabId.value)
        }
    }, {
        deep: true,
    })

    watch(() => props.isLoading, (newValue: boolean) => {
        sideNavKey.value = newValue ? Math.random() : sideNavKey.value
    })

    onMounted(() => {
        tabController.value.getTabs()
        actionController.value.getDefaultActions()
        renderComponents()
    })
</script>
